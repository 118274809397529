import React from 'react';

import { Link } from 'react-router-dom';
import './next-button.scss';
import '../../common.scss';
import { useState } from 'react';
import { Path } from '../../const';

const NextButton = ({
  handleNextPage,
  isNextDisabled,
  isAgreed,
  setIsAgreed, }) => {
  const [agreement, setAgreement] = useState(true);

  const nextButtonHandler = () => {
    setAgreement(false);
    handleNextPage();
  };

  return (
    <div className="next-block">
      <button
        onClick={nextButtonHandler}
        className="next__button button"
        type="button"
        disabled={isNextDisabled || !isAgreed}
      >
        далее
      </button>
      {
        agreement
          ? <span>нажимая на кнопку далее вы даёте <Link target="_blank" to={Path.PERSONAL}>согласие на обработку персональных данных</Link></span>
          : null
      }

      <label className='next-agreement-label'>
        <input
          type="checkbox"
          className='next-agreement'
          value={isAgreed}
          checked={isAgreed}
          onChange={() => setIsAgreed(!isAgreed)} />
          Согласен
      </label>
    </div>
  )
}

export default NextButton;