import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL, Path, Url } from "../../const";
import MentorItem from "../mentor-item/mentor-item";
import Cookies from 'js-cookie';
import browserHistory from "../../browser-history";

import './moderator.scss';
import ModeratorMain from "../moderator-main/moderator-main";
import ModeratorUsers from "../moderator-users/moderator-users";
import { useFetchData } from "../../hooks/use-fetch-data";
import ModeratorMentors from "../moderator-mentors/moderator-mentors";
import { adaptToDropdown } from "../../utils";

const Tab = {
    MAIN: `main`,
    MENTOR_LIST: `mentorList`,
    USERS_LIST: `usersList`,
}

const tabs = [
    {
        label: `Главная`,
        value: Tab.MAIN
    },
    {
        label: `Список менторов`,
        value: Tab.MENTOR_LIST
    },
    {
        label: `Список участников`,
        value: Tab.USERS_LIST
    },
]

const Moderator = () => {
    const [tab, setTab] = useState(window.sessionStorage.getItem(`tab`)
        ? window.sessionStorage.getItem(`tab`)
        : Tab.MAIN);

    const [employmentsList, setEmploymentsList] = useState(null);

    useEffect(() => {
        if (!Cookies.get(`moder_key`)) {
            browserHistory.push(Path.MODERATOR_AUTH)
        }
    }, [])

    const [usersFilter, setUsersFilter] = useState({
        name: null,
        limit: 1,
        offset: 0
    })
    const [mentorsFilter, setMentorsFilter] = useState({
        name: null,
        limit: window.sessionStorage.getItem(`limit`)
            ? +window.sessionStorage.getItem(`limit`)
            : 1,
        offset: 0
    })
    const [trigger, setTrigger] = useState(false)
    const [usersTrigger, setUsersTrigger] = useState(false)

    const [users, isLoading] = useFetchData(BASE_URL + Url.FETCH_USERS, `POST`, usersFilter, usersTrigger);
    const [mentors, isMentorsLoading] = useFetchData(BASE_URL + Url.FETCH_MENTORS, `POST`, mentorsFilter, trigger);
    const [educationList] = useFetchData(BASE_URL + Url.FETCH_EDUCATION_LIST, `GET`);
    const [directionList] = useFetchData(BASE_URL + Url.FETCH_DIRECTION_LIST, `GET`);
    const [limitedStatesList] = useFetchData(BASE_URL + Url.FETCH_LIMITED_STATES_LIST, `GET`);
    const [citiesList] = useFetchData(BASE_URL + Url.FETCH_CITIES, `GET`);

    useEffect(() => {
        const fetchEmployments = async () => {
            try {
                const { data } = await axios.request({
                    method: `GET`,
                    url: BASE_URL + Url.FETCH_EMPLOYMENT_LIST
                })

                let adaptedEmployments = [];

                for (const employment of data) {
                    adaptedEmployments.push(adaptToDropdown(employment))
                }
                setEmploymentsList(adaptedEmployments)
            } catch (err) {
                console.log(err)
            }
        }



        fetchEmployments()
    }, [])

    return (
        <div className="moderator">

            <div className="moderator__tabs-list">
                {
                    tabs.map(({ value, label }) => {
                        return (
                            <label
                                key={value}
                                className="moderator__tab">
                                <span className="moderator__tab-label">{label}</span>
                                <input
                                    type="radio"
                                    value={value}
                                    onChange={(({ target }) => {
                                        window.sessionStorage.setItem(`tab`, target.value)
                                        setTab(target.value)
                                    })}
                                    checked={value === tab ? true : false} />
                                <span className="moderator__tab-input" />
                            </label>
                        )
                    })
                }
            </div>

            {
                tab === Tab.MAIN && <ModeratorMain />
            }
            {
                tab === Tab.MENTOR_LIST && <div className="mentors-list">
                    {
                        mentors &&
                        citiesList &&
                        employmentsList &&
                        limitedStatesList &&
                        directionList &&
                        <ModeratorMentors
                            mentors={mentors}
                            mentorsFilter={mentorsFilter}
                            setMentorsFilter={setMentorsFilter}
                            isLoading={isMentorsLoading}
                            educationList={educationList}
                            directionList={directionList}
                            limitedStatesList={limitedStatesList}
                            employmentsList={employmentsList}
                            citiesList={citiesList}
                            trigger={trigger}
                            setTrigger={setTrigger}
                        />
                    }
                </div>
            }

            {tab === Tab.USERS_LIST && users && <ModeratorUsers
                usersFilter={usersFilter}
                setUsersFilter={setUsersFilter}
                users={users}
                isLoading={isLoading}
                setUsersTrigger={setUsersTrigger}
            />}
        </div>
    )
}

export default Moderator;