
// export const BASE_URL = `https://0cfc-185-196-238-169.ngrok.io`;
// export const BASE_URL = `http://135.181.39.55:8780`;
const BASE_DOMEN = `https://${window.location.host}`

// export const BASE_URL = `http://135.181.3.26:8088`;
// export const BASE_OPEN_URL = `http://135.181.3.26:1488`;

export const BASE_URL = BASE_DOMEN + `/api`;
export const BASE_OPEN_URL = BASE_DOMEN;

export const PHOTO_URL = `https://terramentorsmanager.ru/upload`
export const API_KEY = `7f5cbd0f-40fe-47c8-ad48-b5d710abf915`;

export const repeationData = [
  {
    name: 'repeation',
    value: 0,
    description: `0`
  },
  {
    name: 'repeation',
    value: 1,
    description: `1`
  },
  {
    name: 'repeation',
    value: 2,
    description: `2`
  },
  {
    name: 'repeation',
    value: 3,
    description: `3`
  },
  {
    name: 'repeation',
    value: 4,
    description: `4+`
  },
];

export const incomeData = [
  {
    name: 'income',
    value: 'newcomer',
    description: '0 - 50 000 р. (только СТАРТ)',
    id: 1
  },
  {
    name: 'income',
    value: '50-300000',
    description: '50 - 300 000 р. (только ЗАПУСК)',
    id: 2
  },
  {
    name: 'income',
    value: 'more than 300000',
    description: 'от 300 000 р. (только ПРОРЫВ)',
    id: 3
  },
  // {
  //   name: 'income',
  //   value: 'newcomer',
  //   description: '0 - 100 000 р. (только Запуск)',
  //   id: 1
  // },
  // {
  //   name: 'income',
  //   value: '0-100000',
  //   description: '100 - 200 000 р. (только Запуск)',
  //   id: 2
  // },
  // {
  //   name: 'income',
  //   value: '100000-200000',
  //   description: '200 000 - 300 000 р. (только Запуск)',
  //   id: 3
  // },
  // {
  //   name: 'income',
  //   value: '200000-300000',
  //   description: '300 000 - 1 000 000 р. (Прорыв)',
  //   id: 4
  // },
  // {
  //   name: 'income',
  //   value: 'more than 300000',
  //   description: '1 000 000 р. и более (Прорыв)',
  //   id: 5
  // }
];

export const incomeDataBreakthrough = [
  {
    name: 'income',
    value: '0-300000',
    description: '0 - 300 000 р. (только Запуск)',
    id: 1
  },
  {
    name: 'income',
    value: 'more than 300000',
    description: '300 000 р. и более (Прорыв)',
    id: 5
  }
];

export const formatData = [
  {
    name: 'leadership-format',
    value: 'online',
    description: 'Онлайн',
    id: 1
  },
  {
    name: 'leadership-format',
    value: 'offline',
    description: 'Офлайн',
    id: 2
  },
];

export const statusData = [
  {
    name: 'business-type',
    value: 'start',
    description: 'Запуск (для предпринимателей от 0 до 300 000 руб/мес)',
    id: 2
  },
  {
    name: 'business-type',
    value: 'breakthrough',
    description: 'Прорыв (для предпринимателей 300 000 + руб/мес)',
    id: 1
  },
];

export const Path = {
  DEFAULT: `/`,
  MENTOR: `/mentor`,
  AUTHORIZATION: `/authorization`,
  CODE_PAGE: `/code-page`,
  PERSONAL: `/personal`,
  MODERATOR: `/moderator`,
  MODERATOR_AUTH: `/moderator-auth`,
  LEADER: `/leader`
}

export const AuthorizationStatus = {
  AUTH: `AUTH`,
  NO_AUTH: `NO_AUTH`
};

export const DirectionTypeId = {
  START: 1,
  BREAKTHROUGH: 2,
  START_AND_BREAKTHROUGH: 3
};

export const EducationTypeId = {
  OFFLINE: 1,
  ONLINE: 2,
  OFFLINE_AND_ONLINE: 3
};

export const Income = {
  MORE_300000: 'more than 300000',
  LESS_300000: '299',
  AVERAGE: `100000-200000`
}


// export const BASE_URL = `http://94.130.230.165:8019`;


const MANAGER = `/manager`
const REGISTRATION = `/registration`
const LISTS = `/lists`
const MENTOR = `/mentor`

export const Url = {
  VERIFY: `${REGISTRATION}/create_registration_tg_verify_code`,
  CHECK_VERIFY: `${REGISTRATION}/check_tg_verify`,
  FETCH_MENTORS: `${MANAGER}/fetch_mentors`,
  FETCH_MENTORS_FOR_USER: `${REGISTRATION}/fetch_mentors`,
  MODERATOR_AUTH: `${MANAGER}/login`,
  CREATE_MENTOR_INVITE_TOKEN: `${MANAGER}/create_mentor_invite_token`,
  CREATE_USER_INVITE_TOKEN: `${MANAGER}/create_user_invite_token`,
  FETCH_USERS: `${MANAGER}/fetch_users`,
  FETCH_MENTORS_USERS: `${MENTOR}/fetch_users`,
  FETCH_EDUCATION_LIST: `${LISTS}/education`,
  FETCH_DIRECTION_LIST: `${LISTS}/direction`,
  UPDATE_MENTOR_DATA: `${MANAGER}/update_mentor_data`,
  FETCH_LIMITED_STATES_LIST: `${LISTS}/limited_states`,
  FETCH_EMPLOYMENT_LIST: `${LISTS}/employment`,
  FETCH_CITIES: `${LISTS}/city`,
  EXPORT_MENTOR_DATA: `${MANAGER}/export_mentor_data`,
  EXPORT_USER_DATA: `${MANAGER}/export_user_data`,
  MENTOR_LOGIN: `${MENTOR}/login`,
  EMAIL_CONFIRMATION: `${MENTOR}/request_email_confirmation`,
  FETCH_TG_VERIFY_CODES: `${MANAGER}/fetch_tg_verify_codes`,
  DELETE_TG_VERIFY_CODE: `${MANAGER}/delete_tg_verify_code`,
  REQUEST_PASSWORD_UPDATE: `${MENTOR}/request_password_update`,
  UPDATE_PASSWORD: `${MENTOR}/update_password`,
  UPDATE_ACTIVE_STATUS: `${MENTOR}/update_active_state`,
  UPDATE_MENTOR_APPROVED_STATUS: `${MANAGER}/update_mentor_approved_status`,
  UPDATE_USER_STATE: `${MENTOR}/update_user_state`,
  GET_ACTIVE_STATE: `${MENTOR}/get_active_state`,
  GET_EARLY_ACCESS_TOKEN_INFO: `${REGISTRATION}/get_early_access_token_info`,
  REGISTER_USER: `${REGISTRATION}/register_user`,
  EARLY_REGISTER_USER: `${REGISTRATION}/early_register_user`,
}
