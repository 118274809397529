import axios from "axios";
import { useEffect, useReducer, useRef, useState } from "react";
import { API_KEY, BASE_URL, Path, PHOTO_URL, Url } from "../../const";
import { v4 as uuidv4 } from 'uuid';

import Select from 'react-select';
import Cookies from 'js-cookie'

import './mentor-item.scss';
import { getBase64 } from "../../utils";
import Spinner from "../spinner/spinner";

const ActionType = {
    UPDATE_MENTOR: `UPDATE_MENTOR`,
    SET_NAME: `SET_NAME`,
    SET_LASTNAME: `SET_LASTNAME`,
    SET_COMPANY_NAME: `SET_COMPANY_NAME`,
    SET_APPROVED: `SET_APPROVED`,
    SET_CERTIFIED: `SET_CERTIFIED`,
    SET_CITY: `SET_CITY`,
    SET_COMPANY_ANNUAL_TURNOVER: `SET_COMPANY_ANNUAL_TURNOVER`,
    SET_NEW_PHOTO: `SET_NEW_PHOTO`,
    SET_VIDEO: `SET_VIDEO`,
    SET_EDUCATION_TYPE: `SET_EDUCATION_TYPE`,
    SET_DIRECTION_TYPE: `SET_DIRECTION_TYPE`,
    SET_DIRECTION_TG_LINK: `SET_DIRECTION_TG_LINK`,
    SET_IS_ACTIVE: `SET_IS_ACTIVE`,
    SET_REGISTRATION_LIMIT: `SET_REGISTRATION_LIMIT`,
    TRANSFORM_COMPETENCE_LIST: `TRANSFORM_COMPETENCE_LIST`,
    ADD_COMPETENCE: `ADD_COMPETENCE`,
    EDIT_COMPETENCE: `EDIT_COMPETENCE`,
    DELETE_COMPETENCE: `DELETE_COMPETENCE`,
    TRANSFORM_EMPLOYMENTS_LIST: `TRANSFORM_EMPLOYMENTS_LIST`,
    SET_EMPLOYMENTS: `SET_EMPLOYMENTS`,

    SET_TRANSFORMED_DIRECTIONS_LIST: `SET_TRANSFORMED_DIRECTIONS_LIST`,
    SET_TRANSFORMED_COMPETENCES_LIST: `SET_TRANSFORMED_COMPETENCES_LIST`,
};

const competencesReducer = (state, action) => {
    switch (action.type) {
        case ActionType.SET_TRANSFORMED_COMPETENCES_LIST:
            let transformedList = [];

            for (const competence of action.payload) {
                transformedList.push({
                    name: competence.name ? competence.name : competence,
                    id: uuidv4()
                })
            }

            return transformedList;
        case ActionType.ADD_COMPETENCE:
            const newCompetenceItem = {
                id: uuidv4(),
                name: ``
            };
            return [...state, newCompetenceItem]
        case ActionType.EDIT_COMPETENCE:
            const { competenceName, id } = action.payload;

            const updCompetenceItem = state.find(item => item.id === id);
            const updCompetenceIdx = state.findIndex(item => item.id === id);

            updCompetenceItem.name = competenceName;

            return [
                ...state.slice(0, updCompetenceIdx),
                updCompetenceItem,
                ...state.slice(updCompetenceIdx + 1),
            ]
        case ActionType.DELETE_COMPETENCE:
            const { compteneceId } = action.payload;

            const deleteCompetenceIdx = state.findIndex(item => item.id === compteneceId);

            return [
                ...state.slice(0, deleteCompetenceIdx),
                ...state.slice(deleteCompetenceIdx + 1),
            ]
        default:
            throw new Error()
    }
}


const directionsReducer = (state, action) => {
    switch (action.type) {
        case ActionType.SET_TRANSFORMED_DIRECTIONS_LIST:
            return action.payload

        case ActionType.SET_DIRECTION_TYPE:
            const { isDirectionChecked, directionName, directionID } = action.payload;

            const updatingDirItem = state.find(item => item.displayName === directionName);
            const updatingDirIdx = state.findIndex(item => item.displayName === directionName);
            let newItem;

            if (!updatingDirItem || updatingDirIdx < 0) {
                newItem = {
                    displayName: directionName,
                    tgInviteLink: null,
                    directionID
                }
            }

            let updatedDirList = [];


            if (isDirectionChecked) {
                updatedDirList = [...state, newItem]
            } else {
                updatedDirList = [...state.slice(0, updatingDirIdx), ...state.slice(updatingDirIdx + 1)]
            }

            return updatedDirList
        case ActionType.SET_DIRECTION_TG_LINK:
            const { tgLinkValue, directionListItem } = action.payload;
            const updatingDirTGItem = state.find(item => item.displayName === directionListItem);
            const updatingDirTGIdx = state.findIndex(item => item.displayName === directionListItem);

            updatingDirTGItem.tgInviteLink = tgLinkValue

            return [
                ...state.slice(0, updatingDirTGIdx),
                updatingDirTGItem,
                ...state.slice(updatingDirTGIdx + 1)
            ]

        default:
            throw new Error();
    }
}

const mentorReducer = (state, action) => {
    switch (action.type) {
        case ActionType.UPDATE_MENTOR:
            const {
                ID,
                approved,
                certified,
                cityName,
                companyAnnualTurnover,
                companyName,
                directionList,
                educationList,
                firstName,
                lastName,
                photoURL,
                videoURL,
                active,
                registrationLimit,
                competenceList,
                employmentList,
                username,
                canRegister,
                directionListItems
            } = action.payload;

            console.log(`mentor `, username)
            return {
                ...state,
                ID,
                approved,
                certified,
                cityName,
                companyAnnualTurnover,
                companyName,
                directionList,
                educationList,
                firstName,
                lastName,
                photoURL,
                videoURL,
                active,
                registrationLimit,
                competenceList,
                employmentList,
                username,
                canRegister,
                directionListItems
            }

        case ActionType.SET_NAME:
            return {
                ...state,
                firstName: action.payload
            }
        case ActionType.SET_LASTNAME:
            return {
                ...state,
                lastName: action.payload
            }
        case ActionType.SET_COMPANY_NAME:
            return {
                ...state,
                companyName: action.payload
            }
        case ActionType.SET_APPROVED:
            return {
                ...state,
                approved: !state.approved
            }
        case ActionType.SET_CERTIFIED:
            return {
                ...state,
                certified: !state.certified
            }
        case ActionType.SET_CITY:
            return {
                ...state,
                cityName: action.payload
            }
        case ActionType.SET_COMPANY_ANNUAL_TURNOVER:
            return {
                ...state,
                companyAnnualTurnover: action.payload
            }
        case ActionType.SET_NEW_PHOTO:
            return {
                ...state,
                photoURL: action.payload
            }
        case ActionType.SET_VIDEO:
            return {
                ...state,
                videoURL: action.payload
            }
        case ActionType.SET_IS_ACTIVE:
            return {
                ...state,
                active: action.payload
            }
        case ActionType.SET_EDUCATION_TYPE:
            const { value, name } = action.payload;
            const updatingIdx = state.educationList.findIndex(item => item === name);
            let updatedList = [];


            if (value) {
                updatedList = [...state.educationList, name]
            } else {
                updatedList = [...state.educationList.slice(0, updatingIdx), ...state.educationList.slice(updatingIdx + 1)]
            }

            return {
                ...state,
                educationList: updatedList
            }
        case ActionType.SET_REGISTRATION_LIMIT:

            const { regLimitValue, regDisplayName } = action.payload;
            const updatingRegItem = state.registrationLimit.find(item => item.displayName === regDisplayName);
            const updatingRegIdx = state.registrationLimit.findIndex(item => item.displayName === regDisplayName);
            updatingRegItem.regLimit = regLimitValue

            return {
                ...state,
                registrationLimit: [
                    ...state.registrationLimit.slice(0, updatingRegIdx),
                    updatingRegItem,
                    ...state.registrationLimit.slice(updatingRegIdx + 1)
                ]

            }
        case ActionType.TRANSFORM_COMPETENCE_LIST:
            let transformedList = [];

            for (const competence of state.competenceList) {
                transformedList.push({
                    name: competence,
                    id: uuidv4()
                })
            }

            return {
                ...state,
                competenceList: transformedList
            }
        case ActionType.SET_EMPLOYMENTS:
            return {
                ...state,
                employmentList: action.payload
            }
        default:
            throw new Error()
    }
}

const MentorItem = ({
    mentor,
    educationListItems,
    directionListItems,
    limitedStatesList,
    employmentsList,
    citiesList,
    setTrigger,
    trigger
}) => {
    const mentorPhotoRef = useRef()


    const [transformedEmployments, setTransformedEmployments] = useState(null);
    const [transformedDirections, dirDispatch] = useReducer(directionsReducer, null);
    const [transformedCompetences, compDispatch] = useReducer(competencesReducer, null);
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isMentorAvailableToApprove, setIsMentorAvailableToApprove] = useState(false);

    const [errorChat, setErrorChat] = useState(``)
    const [mentorInfo, dispatch] = useReducer(mentorReducer, {
        ...mentor,
        educationListItems,
        directionListItems,
        limitedStatesList,
        employmentsList,
        citiesList
    })


    const {
        ID,
        approved,
        certified,
        cityName,
        companyAnnualTurnover,
        companyName,
        directionList,
        educationList,
        firstName,
        lastName,
        photoURL,
        videoURL,
        active,
        registrationLimit,
        competenceList,
        employmentList,
        username
    } = mentorInfo;


    const updateHandler = async (evt) => {
        setErrorMessage(null)
        evt.preventDefault();
        setIsLoading(true)

        let checkedEducationList = educationListItems
            .filter(({ name }) => educationList.includes(name));

        if (checkedEducationList.length) {
            checkedEducationList = checkedEducationList
                .map(({ ID }) => ID)
        }

        let limitList = [];

        for (let i = 0; i < registrationLimit.length; i++) {
            for (let k = 0; k < limitedStatesList.length; k++) {
                if (registrationLimit[i].displayName === limitedStatesList[k].name) {
                    limitList.push({
                        stateID: limitedStatesList[k].ID,
                        limit: +registrationLimit[i].regLimit
                    })
                }
            }
        }

        let employmentsIDList = [];

        for (let i = 0; i < transformedEmployments.length; i++) {
            for (let k = 0; k < employmentsList.length; k++) {
                if (transformedEmployments[i].label === employmentsList[k].label) {
                    employmentsIDList.push(employmentsList[k].value)
                }
            }
        }

        const comptencesStrings = transformedCompetences.map(({ name }) => name);

        if (!comptencesStrings.length) {
            setIsLoading(false)
            return setErrorMessage(`Добавьте минимум одну компетенцию`)
        }

        if (comptencesStrings.length !== [...new Set(comptencesStrings)].length) {
            setIsLoading(false)
            return setErrorMessage(`Компетенции не должны совпадать по названию`)
        }

        const cityID = cityName && cityName.value
            ? +cityName.value
            : null

        const directionsFinal = transformedDirections
            .map(({ tgInviteLink, directionID }) => {
                return {
                    directionID,
                    inviteLink: tgInviteLink,
                }
            })

        try {

            await axios.request({
                method: `POST`,
                url: BASE_URL + Url.UPDATE_MENTOR_DATA,
                headers: {
                    Authorization: Cookies.get(`moder_key`)
                },
                data: {
                    mentorID: +ID,
                    data: {
                        educationTypeList: checkedEducationList,
                        directionsList: directionsFinal,
                        employmentList: employmentsIDList,
                        competenceList: comptencesStrings,
                        limitInfo: limitList,
                        active,
                        firstname: firstName,
                        lastname: lastName,
                        cityID,
                        certified,
                        photoURL: photoURL?.url ? photoURL.url : photoURL,
                        videoURL,
                        companyName,
                        companyAnnualTurnover: companyAnnualTurnover ? +companyAnnualTurnover : null,
                    }
                }
            })

            setTrigger(!trigger)
        } catch (err) {
            console.log(err)
            setErrorMessage(`Выберите другой чат`)
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    const deleteCompetenceHandler = (id) => {
        compDispatch({
            type: ActionType.DELETE_COMPETENCE,
            payload: { compteneceId: id }
        })
    }

    const uploadPhotoHandler = ({ target }) => {
        setIsLoading(true)
        const promise = new Promise((res, _rej) => {
            res(
                getBase64(target.files[0])
                    .then((data) => data)
            )
        })

        promise
            .then((res) => {

                const imgRegex = /;base64,.*/
                const bytes = imgRegex.exec(res)[0].slice(8)

                axios.request({
                    method: `POST`,
                    url: PHOTO_URL,
                    headers: {
                        [`API-Key`]: API_KEY,
                        [`Content-Type`]: `application/json`
                    },
                    data: { bytes }
                })
                    .then(({ data }) => {

                        dispatch({
                            type: ActionType.SET_NEW_PHOTO,
                            payload: data
                        })
                        setIsLoading(false)
                    })
            })
    };

    const activateMentorHandler = async (evt) => {
        evt.preventDefault();

        setIsLoading(true)
        try {
            await axios.request({
                method: `POST`,
                url: BASE_URL + Url.UPDATE_MENTOR_APPROVED_STATUS,
                data: {
                    mentorID: ID,
                    approved: !approved
                }
            })
            setTrigger(!trigger)
        } catch (err) {
            console.log(err)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        dispatch({ type: ActionType.UPDATE_MENTOR, payload: mentor })

        dispatch({ type: ActionType.TRANSFORM_COMPETENCE_LIST })

        let transEmployments = [];

        if (mentor && mentor.employmentList && mentor.employmentList.length) {
            for (const competence of mentor.employmentList) {
                for (let i = 0; i < employmentsList.length; i++) {
                    if (competence === employmentsList[i].label) {
                        transEmployments.push({
                            label: competence,
                            value: employmentsList[i].value
                        })
                    }
                }
            }
        }

        setTransformedEmployments(transEmployments)


        if (mentor.directionList) {
            let transDirections = [];
            for (const direction of mentor.directionList) {
                for (let i = 0; i < directionListItems.length; i++) {
                    if (direction.displayName === directionListItems[i].name) {
                        transDirections.push({ ...direction, directionID: directionListItems[i].ID })
                    }
                }
            }




            dirDispatch({ type: ActionType.SET_TRANSFORMED_DIRECTIONS_LIST, payload: transDirections })
        }

        compDispatch({ type: ActionType.SET_TRANSFORMED_COMPETENCES_LIST, payload: mentor.competenceList })

        if (mentor) {
            dispatch({ type: ActionType.SET_NEW_PHOTO, payload: mentor.photoURL })

        }
        console.log(`_________`, mentor.photoURL)
    }, [mentor])

    useEffect(() => {
        if (directionList) {
            if (directionList.every((item) => item.tgInviteLink)) {
                setIsMentorAvailableToApprove(true)
            } else {
                setIsMentorAvailableToApprove(false)
            }
        }
    }, [directionList])

    // if (ID === 1) {
    //     console.log(`companyAnnualTurnover `,companyAnnualTurnover)
    // }

    return (
        <form
            onSubmit={updateHandler}
            className="mentor-item">
            {isLoading && <div className="loader__mentor"><Spinner /></div>}
            <h2>ID наставника: {ID}</h2>
            <h2>Юзернейм: {username}</h2>
            {!isMentorAvailableToApprove && <strong style={{ color: `tomato`, textAlign: `center` }}>добавьте телеграм чаты</strong>}
            <button
                type="button"
                className="button approve-button"
                disabled={!isMentorAvailableToApprove}
                onClick={activateMentorHandler}
                style={{
                    backgroundColor: `${approved ? `tomato` : `green`}`,
                    color: `#fff`
                }}
            >{!approved
                ? `Активировать наставника`
                : `Деактивировать наставника`}
            </button>

            <label>
                <span>Имя</span>
                <input
                    required
                    value={firstName}
                    onChange={(({ target }) => dispatch({
                        type: ActionType.SET_NAME,
                        payload: target.value ? target.value : ``
                    }))} />
            </label>
            <label>
                <span>Фамилия</span>
                <input
                    required
                    value={lastName}
                    onChange={(({ target }) => dispatch({
                        type: ActionType.SET_LASTNAME,
                        payload: target.value ? target.value : ``
                    }))} />
            </label>

            <label>
                <span>Фото</span>
                <img
                    ref={mentorPhotoRef}
                    width="100px"
                    src={
                        photoURL && photoURL.url
                            ? photoURL.url
                            : photoURL}
                    alt="фото наставника" />
                <input
                    className="photo-input"
                    type="file"
                    accept="image/*"
                    value={``}
                    onChange={uploadPhotoHandler} />
            </label>

            <label>
                <span>Видео</span>
                <input
                    required
                    value={videoURL}
                    onChange={(({ target }) => dispatch({
                        type: ActionType.SET_VIDEO,
                        payload: target.value ? target.value : ``
                    }))} />
            </label>
            <label>
                <span>Название компании</span>
                <input
                    value={companyName ? companyName : ``}
                    onChange={(({ target }) => dispatch({
                        type: ActionType.SET_COMPANY_NAME,
                        payload: target.value ? target.value : ``
                    }))} />
            </label>
            <label>
                <span>Оборот компании</span>
                <input
                    type="number"
                    value={companyAnnualTurnover ? companyAnnualTurnover : ``}
                    onChange={(({ target }) => dispatch({
                        type: ActionType.SET_COMPANY_ANNUAL_TURNOVER,
                        payload: target.value ? target.value : ``
                    }))} />
            </label>
            {/* <label>
                <span>Подтвержден</span>
                <input
                    type="checkbox"
                    checked={approved}
                    onChange={(() => dispatch({ type: ActionType.SET_APPROVED }))} />
            </label> */}
            <label>
                <span>Аттестован</span>
                <input
                    type="checkbox"
                    checked={certified}
                    onChange={(() => dispatch({ type: ActionType.SET_CERTIFIED }))} />
            </label>
            <label>
                <span>Активный</span>
                <input
                    value={active}
                    type="checkbox"
                    checked={active}
                    onChange={(({ target }) => dispatch({
                        type: ActionType.SET_IS_ACTIVE,
                        payload: target.checked
                    }))} />
            </label>
            <label className="city-block">
                <span>Город:</span>
                <strong>{cityName && cityName.label
                    ? cityName.label
                    : cityName
                        ? cityName
                        : `не выбран`}</strong>
                <select
                    onChange={(({ target }) => {
                        dispatch({
                            type: ActionType.SET_CITY,
                            payload: target.selectedOptions[0] === `null` ? null : target.selectedOptions[0]
                        })
                    })}
                >
                    <option value={'null'}>выбрать</option>
                    {citiesList.map(({ ID, name }) => <option key={ID} value={ID}>{name}</option>)}
                </select>
            </label>

            <div>
                <h5 className="mentor-item__education-title">Тип обучения: </h5>
                <div className="mentor-item__education-list">
                    {educationListItems &&
                        educationListItems.map(({ ID, name }) => {
                            return (
                                <label key={ID}>
                                    <span>{name}</span>
                                    <input
                                        type="checkbox"
                                        value={ID}
                                        checked={educationList && educationList.includes(name)}
                                        onChange={(({ target }) => dispatch({
                                            type: ActionType.SET_EDUCATION_TYPE,
                                            payload: { value: target.checked, name }
                                        }))}
                                    />
                                </label>
                            )
                        })}
                </div>
            </div>

            <div>
                <h5 className="mentor-item__education-title">Направления: </h5>
                <div className="mentor-item__education-list">
                    {directionListItems && transformedDirections &&
                        directionListItems.map(({ ID, name }) => {
                            let activeDirections = transformedDirections.map(item => item.displayName)
                            let tgLink = transformedDirections.find(item => item.displayName === name)
                                ? transformedDirections.find(item => item.displayName === name).tgInviteLink
                                : ``;

                            let isChecked = activeDirections.includes(name)
                            return (
                                <div key={ID}>
                                    <label>
                                        <span>{name}</span>
                                        <input
                                            type="checkbox"
                                            value={ID}
                                            checked={isChecked}
                                            onChange={(({ target }) => dirDispatch({
                                                type: ActionType.SET_DIRECTION_TYPE,
                                                payload: {
                                                    isDirectionChecked: target.checked,
                                                    directionName: name,
                                                    directionID: ID
                                                }
                                            }))}
                                        />
                                    </label>
                                    {
                                        isChecked &&
                                        <label>
                                            <span>ссылка на чат: </span>
                                            <input
                                                required
                                                value={tgLink ? tgLink : ``}
                                                type="text"
                                                onChange={(({ target }) => dirDispatch({
                                                    type: ActionType.SET_DIRECTION_TG_LINK,
                                                    payload: { tgLinkValue: target.value, directionListItem: name }
                                                }))} />
                                        </label>
                                    }
                                </div>
                            )
                        })}
                </div>
            </div>


            <div>
                {registrationLimit.map(({ regLimit, displayName }) => {
                    return (
                        <label
                            className="regLimit-label"
                            key={displayName}>
                            <span>{displayName}</span>
                            <input
                                className="regLimit-input"
                                min="15"
                                required
                                value={regLimit}
                                onChange={(({ target }) => dispatch({
                                    type: ActionType.SET_REGISTRATION_LIMIT,
                                    payload: { regLimitValue: target.value ? + target.value : ``, regDisplayName: displayName }
                                }))}
                                type="number" />
                        </label>
                    )
                })}
            </div>

            <div className="competence-list">
                <h3>Список компетенций:</h3>
                {transformedCompetences && transformedCompetences.length
                    ? transformedCompetences.map(({ name, id }, idx) => {

                        return (
                            <label
                                key={idx}
                                className="competence-list__label">
                                <input
                                    type="text"
                                    value={name ? name : ``}
                                    onChange={(({ target }) => compDispatch({
                                        type: ActionType.EDIT_COMPETENCE,
                                        payload: { competenceName: target.value, id }
                                    }))}
                                    onBlur={(({ target }) => {
                                        if (target.value === ``) {
                                            deleteCompetenceHandler(id)
                                        }
                                    })}
                                />
                                <button
                                    type="button"
                                    onClick={() => deleteCompetenceHandler(id)}
                                >
                                    x
                                </button>
                            </label>
                        )
                    })
                    : null
                }
                <button
                    type="button"
                    className="button"
                    onClick={(() => compDispatch({ type: ActionType.ADD_COMPETENCE }))}
                >
                    Добавить
                </button>
            </div>

            <div className="employments-block">
                <Select
                    onChange={(value) => setTransformedEmployments(value)}
                    options={employmentsList}
                    value={transformedEmployments}
                    isMulti
                />
            </div>
            <button
                className="button"
                type="submit">
                Применить
            </button>

            {errorMessage
                ? <div style={{ color: `red` }}>{errorMessage}</div>
                : null}
        </form>
    )
}

export default MentorItem;
