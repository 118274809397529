import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { fetchEmployments, fetchMentors, getErrorMessage, getTelegramCode, setEducationList } from '../../store/actions';
import { BASE_URL, DirectionTypeId, Income, EducationTypeId, Url } from '../../const';
import ButtonsBlock from '../buttons-block/buttons-block';
import FirstPage from '../first-page/first-page';
import Leaders from '../leaders/leaders';
import Final from '../final/final';
import TelegramScreen from '../telegram-screen/telegram-screen';

import './form.scss';

const getUserIncome = () => {
  let data = {}
  if (window.location.hostname.includes("start")) {
    data = {
      userIncome: '0-50000',
      directionTypeID: 3
    }
  } else if (window.location.hostname.includes("launch")) {
    data = {
      userIncome: '50000-300000',
      directionTypeID: 2
    }
  } else if (window.location.hostname.includes("breakthrough")) {
    data = {
      userIncome: 'more than 300000',
      directionTypeID: 1
    }
  } else {
    data = {
      userIncome: '0-50000',
      directionTypeID: 3
    }
  }

  return data
}

const Form = () => {

  const dispatch = useDispatch();
  const directionRef = useRef();
  const mockUserData = getUserIncome();

  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [userLastname, setUserLastname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userBusiness, setUserBusiness] = useState('');
  const [userTelegram, setUserTelegram] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userMentoringCount, setUserMentoringCount] = useState('0');
  const [userIncome, setUserIncome] = useState(mockUserData.userIncome);
  const [userEducationType, setUserEducationType] = useState(`1`);
  const [userDirectionType, setUserDirectionType] = useState(mockUserData.directionTypeID);
  const [isAgreed, setIsAgreed] = useState(false)

  const [isBackButton, setIsBackButton] = useState(false)

  const [mentorId, setMentorId] = useState(null);

  const [buttonsBlock, setButtonsBlock] = useState(true);

  const [isFailedRegistration, setIsFailedRegistration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mentors = useSelector(({ LEADERS }) => LEADERS.mentors);
  const reduxEdType = useSelector(({ LEADERS }) => LEADERS.educationType);
  const educationsList = useSelector(({ LEADERS }) => LEADERS.educationsList);
  const isEarlyRegistration = useSelector(({ LEADERS }) => LEADERS.isEarlyRegistration);


  const firstPage = <FirstPage
    directionRef={directionRef}
    setUserName={setUserName}
    setUserSurname={setUserSurname}
    setUserLastname={setUserLastname}
    setUserEmail={setUserEmail}
    setUserBusiness={setUserBusiness}
    setUserTelegram={setUserTelegram}
    setUserPhone={setUserPhone}
    setUserMentoringCount={setUserMentoringCount}
    setUserIncome={setUserIncome}
    setUserEducationType={setUserEducationType}
    setUserDirectionType={setUserDirectionType}

    userName={userName}
    userSurname={userSurname}
    userLastname={userLastname}
    userEmail={userEmail}
    userBusiness={userBusiness}
    userTelegram={userTelegram}
    userPhone={userPhone}
    userMentoringCount={userMentoringCount}
    userIncome={userIncome}
    userEducationType={userEducationType}
    userDirectionType={userDirectionType}

  />

  const [activePage, setPage] = useState(firstPage)

  const [isNextDisabled, setNextButton] = useState(false);
  const [isNextShowed, setNextShow] = useState(true);
  const [isSubmitDisabled, setSubmitButton] = useState(true);
  const [mentorsList, setMentorsList] = useState(null);
  const [isReturnDisabled, setReturnDisabled] = useState(true);

  const [isModalWarning, setIsModalWarning] = useState(false);

  const warningModalHandler = () => {
    setIsModalWarning(null)
  }

  useEffect(() => {
    if (!mentors) {
      axios.request({
        method: `POST`,
        url: BASE_URL + Url.FETCH_MENTORS_FOR_USER,
      })
        .then(({ data }) => {
          if (window.location.hostname.includes("start")) {
            return dispatch(fetchMentors(data.filter((mentor) =>
                mentor.directionList.includes(`Старт`)
            )))
          }
          if (window.location.hostname.includes("launch")) {
            return dispatch(fetchMentors(data.filter((mentor) =>
              mentor.directionList.includes(`Запуск`)
            )))
          }
          if (window.location.hostname.includes("breakthrough")) {
            return dispatch(fetchMentors(data.filter((mentor) =>
              mentor.directionList.includes(`Прорыв`)
            )))
          }
          return dispatch(fetchMentors(data))
          // dispatch(fetchMentors(data))
        })
    }
  }, [mentors, dispatch]);

  useEffect(() => {
    const fetchEducations = async () => {
      try {
        const { data } = await axios.request({
          method: `GET`,
          url: BASE_URL + Url.FETCH_EDUCATION_LIST,
        })
        dispatch(setEducationList(data))
      } catch (err) {
        console.log(err)
      }
    }

    if (!educationsList.length) {
      fetchEducations();
    }
  }, []);

  useEffect(() => {
    if (!mentors) {
      axios
        .get(`${BASE_URL}/lists/employment`)
        .then(({ data }) => {
          console.log(`emp`, data)
          dispatch(fetchEmployments(data))
        })
    }
  }, [mentors, dispatch]);

  useEffect(() => {
    setPage(firstPage)
    setNextButton(true);

    if (userIncome === Income.AVERAGE) {
      if (
        userDirectionType &&
        // userIncome &&
        userEducationType &&
        userName &&
        userSurname &&
        userLastname &&
        userEmail &&
        userBusiness &&
        userMentoringCount &&
        userPhone
        // &&
        // userTelegram
      ) {
        setNextButton(false);
      }
    }

    if (userIncome !== Income.AVERAGE) {
      if (
        userName &&
        userSurname &&
        userLastname &&
        userEmail &&
        userBusiness &&
        userMentoringCount &&
        userPhone &&
        // userTelegram &&
        // userIncome &&
        userEducationType
      ) {
        setNextButton(false);
      }
    }

    if (mentors) {

      const startMentors = mentors.filter(({ directionTypeID }) => {
        return directionTypeID === DirectionTypeId.START
          || directionTypeID === DirectionTypeId.START_AND_BREAKTHROUGH
      });

      const breakthroughtMentors = mentors.filter(({ directionTypeID }) => {
        return directionTypeID === DirectionTypeId.BREAKTHROUGH
          || directionTypeID === DirectionTypeId.START_AND_BREAKTHROUGH
      });

      if (userDirectionType === '2') {
        return setMentorsList(breakthroughtMentors);
      }

      if (userDirectionType === '1') {
        return setMentorsList(startMentors);
      }

    }
  }, [userEducationType, userDirectionType, userName, userSurname, userLastname, userEmail, userBusiness, userMentoringCount, userPhone, userTelegram, userIncome, directionRef])

  const scrollPage = () => window.scroll(0, 0);


  const handleNextPage = () => {

    if (isModalWarning === false && userDirectionType == `1`) {
      return setIsModalWarning(true)
    };
    setIsBackButton(true)
    return (
      setPage(<Leaders
        setUserEducationType={setUserEducationType}
        handleMentorChoice={handleMentorChoice}
        mentorId={mentorId}
        setMentor={setMentorId}
        mentors={mentorsList}
        userDirectionType={userDirectionType} />),
      setNextButton(false),
      setNextShow(false),
      setReturnDisabled(false),
      setTimeout(scrollPage, 0)
    )
  };

  const handleMentorChoice = () => {
    return (
      setPage(<Final />),
      setSubmitButton(false)
    )
  };

  const handlePreviousPage = () => {
    if (document.querySelector(`.second`)) {
      return (
        setIsModalWarning(false),
        setPage(firstPage),
        setReturnDisabled(true),
        setNextButton(false),
        setNextShow(true),
        setSubmitButton(true),
        setIsBackButton(false)
      )
    } else {
      return (
        setPage(<Leaders
          setUserEducationType={setUserEducationType}
          handleMentorChoice={handleMentorChoice}
          mentorId={mentorId}
          setMentor={setMentorId}
          mentors={mentorsList}
          userDirectionType={userDirectionType} />),
        setReturnDisabled(false),
        setSubmitButton(true),
        setIsBackButton(true)
      )
    }
  }


  const handleSubmitForm = (evt) => {
    setIsFailedRegistration(false)
    setIsLoading(true)
    evt.preventDefault();

    // const getTelegradId = () => {
    //   if (userTelegram.includes('@')) {
    //     return userTelegram.slice(1, userTelegram.length)
    //   }
    //   return userTelegram;
    // }

    const data = {
      firstname: userName,
      lastname: userSurname,
      patronymic: userLastname,
      email: userEmail,
      // tgUsername: getTelegradId(),
      phoneNumber: userPhone,
      employments: userBusiness,
      monthlyIncome: userIncome,

      mentoringCount: +userMentoringCount,
      educationTypeID: +reduxEdType,
      directionTypeID: +userDirectionType,
      mentorID: +mentorId,
      registrationID: localStorage.getItem(`registrationID`)
    };

    if (isEarlyRegistration) {
      data.earlyAccessToken = sessionStorage.getItem(`inviteCode`)
    }


    const url = isEarlyRegistration ? Url.EARLY_REGISTER_USER : Url.REGISTER_USER

    axios
      .post(BASE_URL + url, data)
      .then(({ data }) => data.message ? dispatch(getErrorMessage(data.message)) : dispatch(getTelegramCode(data.tgVerifyCode)))
      .then(() => setButtonsBlock(false))
      .then(() => setPage(<TelegramScreen />))
      .then(() => setIsLoading(false))
      .catch((err) => {
        setIsFailedRegistration(true)
        setIsLoading(false)
      })
  };

  return (
    <form
      className="form container"
      onSubmit={(evt) => handleSubmitForm(evt)}>
      {activePage}

      {
        buttonsBlock
          ? <ButtonsBlock
            isAgreed={isAgreed}
            setIsAgreed={setIsAgreed}
            isNextShowed={isNextShowed}
            isNextDisabled={isNextDisabled}
            isSubmitDisabled={isSubmitDisabled}
            handleNextPage={handleNextPage}
            isReturnDisabled={isReturnDisabled}
            handlePreviousPage={handlePreviousPage}
            isFailedRegistration={isFailedRegistration}
            isLoading={isLoading}
            isBackButton={isBackButton} />
          : null
      }

      {/*{*/}
      {/*  isModalWarning && <div className='modal__warning-wrapper'>*/}
      {/*    <div className='modal__warning'>*/}
      {/*      <span>*/}
      {/*        Ты точно соответствуешь уровню <strong style={{ color: `green` }}>прорыв</strong>? Если не соответствуешь - имей в виду, не попадешь к наставнику даже если зарегистрируешься!*/}
      {/*      </span>*/}
      {/*      <button*/}
      {/*        type='button'*/}
      {/*        className='next__button button warning__button'*/}
      {/*        onClick={warningModalHandler}*/}
      {/*      >Да</button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*}*/}

    </form>
  )
}

export default Form;
