import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Leader from '../leader/leader';

import './leaders.scss';

const formatOptions = [
  {
    name: `онлайн`,
    value: 1,
  },
  {
    name: `офлайн`,
    value: 2,
  },
  {
    name: `офлайн+онлайн`,
    value: 3,
  },
  {
    name: `офлайн Санкт-Петербург`,
    value: 4,
  },
];

const Leaders = ({
  setMentor,
  mentorId,
  handleMentorChoice,
  setUserEducationType,
  userDirectionType }) => {

  const mentors = useSelector(({ LEADERS }) => LEADERS.mentors);
  const [mentorsList, setList] = useState(mentors);
  const [activeEducationFilter, setActiveEducationFilter] = useState(`3`);

  const employmentsList = useSelector(({ LEADERS }) => LEADERS.employmentsList);

  if (!mentors) {
    return (
      <div className="container__inner">
        <div className="leaders__empty">Всех разобрали или по вашим параметрам никого нет (как говорится: кто не успел - тот опоздал)</div>
      </div>
    );
  }

  const searchHandler = (target) => {

    const { value } = target;
    const val = value.toLowerCase();

    if (target.value === '') {
      const filteredWithEducationType = mentors.filter(({ educationTypeID }) => educationTypeID === +activeEducationFilter);
      setList(mentors);
    } else {
      const filteredMentors = mentors.filter(({ fullName, employmentList }) => {
        const isFullNameMatches = fullName.toLowerCase().includes(val)

        if (isFullNameMatches) {
          return true;
        }
        if (employmentList) {
          return employmentList.some((employment) => {
            return (
              employment.toLowerCase().includes(val)
            )
          });
        }
      });
      // const filteredWithEducationType = filteredMentors.filter(({ educationTypeID }) => educationTypeID === +activeEducationFilter);
      // setList(filteredWithEducationType);

      setList(filteredMentors);
    }
  };

  const filterByEducationTypeHandler = ({ target }) => {
    // console.log(`target.value `, target.value)
    // console.log(`mentors `, mentors)

    let newMentors = [];
    mentors.forEach((mentor) => {
      const { educationList, cityName } = mentor
      switch (target.value) {
        case `1`:
          if (educationList.includes(`Онлайн`)) {
            newMentors.push(mentor)
          }
          break;
        case `2`:
          if (educationList.includes(`Офлайн`)) {
            newMentors.push(mentor)
          }
          break;
        case `3`:
          if (educationList.includes(`Офлайн`) && educationList.includes(`Онлайн`)) {
            newMentors.push(mentor)
          }
        case `4`:
          if (cityName === `Санкт-Петербург`) {
            newMentors.push(mentor)
          }
        default:
          setList(mentors);
          break;
      }
    });
    setList(newMentors)

    // console.log(`newMentors `, newMentors)
    // if (target.value !== `3`) {
    // } else {
    //   setList(mentors);
    // }

    // setList(mentors);

    setActiveEducationFilter(target.value);
  };


  return (
    <section className="second">
      <div className="container__inner form__radio-container">
        <h3 className="radio__header radio__header--mentors">Выберите наставника из списка</h3>
        <div className="container__wrapper">
          <h4 className="form__radio-header">Поиск по наставникам</h4>
          <input
            type="text"
            className="form__search-field"
            placeholder="введите имя наставника"
            onChange={({ target }) => searchHandler(target)}
          />
          <h4 className="form__radio-header">Поиск по нишам</h4>
          <input
            type="text"
            className="form__search-field"
            placeholder="введите или выберете нишу наставника"
            name="business"
            list="business"
            onChange={({ target }) => searchHandler(target)}
          />
          <datalist id="business">
            {
              employmentsList && employmentsList.length && employmentsList.map(({ name }, idx) => {
                return (
                  <option key={idx} value={name}></option>
                )
              })
            }
          </datalist>

          {/* <div className="form__radio-wrapper">
            <h4 className="form__radio-header">Формат проведения наставничества:</h4>
            {
              formatOptions.map(({ name, value }, idx) => {
                return (
                  <label
                    key={idx}
                  >
                    {name}
                    <input
                      name="format"
                      type="radio"
                      value={value}
                      checked={(+activeEducationFilter === value ? true : null) || ``}
                      onChange={filterByEducationTypeHandler}
                    />
                  </label>
                )
              })
            }
          </div> */}
        </div>
      </div>

      {
        mentorsList.map((mentor) => {
          return (
            <Leader
              setUserEducationType={setUserEducationType}
              key={mentor.ID}
              handleMentorChoice={handleMentorChoice}
              mentorId={mentorId}
              setMentor={setMentor}
              mentor={mentor}
              userDirectionType={userDirectionType} />
          )
        })
      }
      {
        !mentorsList.length
          ? <div className="container__inner form__radio-container">
            <h3>По вашему запросу наставник не найден, попробуйте изменить параметры поиска.</h3>
          </div>
          : null
      }

    </section>
  )
}

export default Leaders;