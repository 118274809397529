import './pagination.scss';

const Pagination = ({ length, limit, page, pageHandler, limitHandler }) => {
    return (
        <div className='pagination-block'>
            <label className='pagination'>
                <span>страница:</span>
                <input
                    type="number"
                    min="1"
                    max={Math.ceil(length / limit)}
                    value={page}
                    onChange={pageHandler}
                />
            </label>
            <label>
                <span>кол-во на странице: </span>
                <input
                    className='input-amount'
                    type="number"
                    min="1"
                    value={limit ? limit : ``}
                    onChange={limitHandler} />
            </label>
        </div>
    )
}

export default Pagination;