import React from 'react';
import {Switch, Route} from "react-router-dom";

import {Path} from '../../const';

import Main from '../main/main';
import MentorAuth from '../mentor-auth/mentor-auth';
import MentorPage from '../mentor-page/mentor-page';
import ModeratorAuth from '../moderator-auth/moderator-auth';
import Moderator from '../moderator/moderator';
import Personal from '../personal/personal';

console.log(`process.env.test `,process.env.REACT_APP_TEST)
const App = () => {
  return (
  <Switch>

    <Route exact path={Path.AUTHORIZATION}>
      <MentorAuth />
    </Route>

    <Route exact path={Path.DEFAULT}>
      <Main />
    </Route>

    <Route exact path={Path.LEADER}>
      <Main isLeader={true} />
    </Route>

    <Route exact path={Path.MENTOR}>
      <MentorPage />
    </Route>

    <Route exact path={Path.PERSONAL}>
      <Personal />
    </Route>

    <Route exact path={Path.MODERATOR}>
      <Moderator />
    </Route>

    <Route exact path={Path.MODERATOR_AUTH}>
      <ModeratorAuth />
    </Route>
  </Switch>
  );
}

export default App;
