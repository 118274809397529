import { useState } from "react";
import { getLimit } from "../../utils";
import MentorItem from "../mentor-item/mentor-item";
import Pagination from "../pagination/pagination";
import Spinner from "../spinner/spinner";
import './moderator-mentors.scss';

const ModeratorMentors = ({
    mentors,
    mentorsFilter,
    setMentorsFilter,
    isLoading,
    educationList,
    directionList,
    limitedStatesList,
    employmentsList,
    citiesList,
    setTrigger,
    trigger }) => {
    const [page, setPage] = useState(1);

    const {
        totalLen,
        data
    } = mentors;

    // console.log(`mentors `,data)
    
    const {
        name,
        limit,
        offset } = mentorsFilter

    const pageHandler = ({ target }) => {
        const { value } = target;

        if ((value - 1) * limit < totalLen && +value && !isLoading) {
            setPage(+value)
            setMentorsFilter(state => ({
                ...state,
                offset: (value - 1) * limit
            }))
        }
    }

    const limitHandler = ({ target }) => {
        setPage(1)
        window.sessionStorage.setItem(`limit`, target.value)
        setMentorsFilter(state => ({
            ...state,
            limit: target.value ? +target.value : 1,
            offset: 0
        }))
    }

    const searchMentorHandler = ({ target }) => {
        setPage(1)
        setMentorsFilter(state => ({
            ...state,
            name: target.value ? target.value : null,
            offset: 0
        }))
    }

    return (
        <div>
            {isLoading && <div className="spinner-absolute"><Spinner /></div>}
            <div className='users-filters'>
                <Pagination
                    length={totalLen}
                    limit={limit}
                    page={page}
                    pageHandler={pageHandler}
                    limitHandler={limitHandler} />
                <label>
                    <span>Поиск по имени: </span>
                    <input
                        type="text"
                        value={name ? name : ``}
                        onChange={searchMentorHandler}
                    />
                </label>
            </div>
            <div className="mentor-list-inner">
                {data && data.length && data.map((mentor, index) => {
                    return (
                        <MentorItem
                            key={index}
                            educationListItems={educationList}
                            mentor={mentor}
                            directionListItems={directionList}
                            limitedStatesList={limitedStatesList}
                            employmentsList={employmentsList}
                            citiesList={citiesList}
                            setTrigger={setTrigger}
                            trigger={trigger} />)
                })}
            </div>
            <div> {totalLen ? (page * limit) - limit + 1 : 0} - {getLimit(page, limit, totalLen)} Из {totalLen}</div>
        </div>
    )
}

export default ModeratorMentors;