import {createReducer} from '@reduxjs/toolkit';
import { AuthorizationStatus } from '../const';
import {setIsEarlyRegistration, getTelegramCode, fetchMentors, fetchStudents, requireAuthorization, getToken, getErrorMessage, fetchEmployments, changeEducationType, setEducationList, setCertifiedAccess} from './actions';

const initialState = {
  code: null,
  mentors: null,
  students: null,
  authorizationStatus: AuthorizationStatus.NO_AUTH,
  token: null,
  errorMessage: null,
  employmentsList: null,
  educationType: null,
  educationsList: [],
  certifiedAccess: false,
  isEarlyRegistration: false,
};

const leaders = createReducer(initialState, (builder) => {
  builder.addCase(setCertifiedAccess, (state, action) => {
    return {
      ...state,
      certifiedAccess: action.payload,
    };
  });
  builder.addCase(setIsEarlyRegistration, (state, action) => {
    return {
      ...state,
      isEarlyRegistration: action.payload,
    };
  });
  builder.addCase(getTelegramCode, (state, action) => {
    return {
      ...state,
      code: action.payload,
    };
  });
  builder.addCase(setEducationList, (state, action) => {
    return {
      ...state,
      educationsList: action.payload,
    };
  });
  builder.addCase(fetchMentors, (state, action) => {
    return {
      ...state,
      mentors: action.payload,
    };
  });
  builder.addCase(fetchStudents, (state, action) => {
    return {
      ...state,
      students: action.payload,
    };
  });
  builder.addCase(requireAuthorization, (state, action) => {
    return {
      ...state,
      authorizationStatus: action.payload,
    };
  });
  builder.addCase(getToken, (state, action) => {
    return {
      ...state,
      token: action.payload,
    };
  });
  builder.addCase(getErrorMessage, (state, action) => {
    return {
      ...state,
      errorMessage: action.payload,
    };
  });
  builder.addCase(fetchEmployments, (state, action) => {
    return {
      ...state,
      employmentsList: action.payload,
    };
  });
  builder.addCase(changeEducationType, (state, action) => {
    return {
      ...state,
      educationType: action.payload,
    };
  });
});

export {leaders};