import {combineReducers} from '@reduxjs/toolkit';

import {leaders} from './leaders';
import { auth } from './auth';

export const NameSpace = {
  LEADERS: `LEADERS`,
  AUTH: `AUTH`
};

export default combineReducers({
  [NameSpace.LEADERS]: leaders,
  [NameSpace.AUTH]: auth,
});
