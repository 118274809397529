import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { requireAuthorization } from '../../store/actions';
import { Path, AuthorizationStatus, BASE_URL, Url } from '../../const';

import './mentor-auth.scss';
import ApiService from '../../store/api-actions';
import Spinner from '../spinner/spinner';
import browserHistory from '../../browser-history';
import show from '../../images/eye-password-hide-svgrepo-com.svg'
import hide from '../../images/eye-close-up-svgrepo-com.svg'

const apiService = new ApiService();

const MentorAuth = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('');
  const [errorBlock, setErrorBlock] = useState(false);
  const [errorBlockEmail, setErrorBlockEmail] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [secondStep, setSecondStep] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newRepeatedPassword, setNewRepeatedPassword] = useState('');
  const [emailToken, setEmailToken] = useState('');
  const [isShow, setIsShow] = useState(false)

  const authorizationStatus = useSelector(({ LEADERS }) => LEADERS.authorizationStatus);


  useEffect(() => {
    const token = localStorage.getItem(`token`);

    if (token) {
      dispatch(requireAuthorization(AuthorizationStatus.AUTH))
      browserHistory.push(Path.MENTOR)
    }
  }, []);


  if (authorizationStatus === AuthorizationStatus.AUTH) {
    return <Redirect to={Path.MENTOR} />;
  }

  const loginHandler = async (evt) => {
    evt.preventDefault();
    setIsLoading(true)
    const options = {
      method: `POST`,
      url: BASE_URL + Url.MENTOR_LOGIN,
      data: {
        username: username,
        password: password
      }
    }

    try {
      const { data } = await axios.request(options)

      dispatch(requireAuthorization(AuthorizationStatus.AUTH))
      localStorage.setItem(`token`, data.sessionKey)

    } catch (err) {
      console.log(err)
      setErrorBlock(true)
    }
    setIsLoading(false)
  }


  const forgetPassHandler = async (evt) => {
    evt.preventDefault()
    try {
      const { data } = await axios.request({
        method: `POST`,
        url: BASE_URL + Url.REQUEST_PASSWORD_UPDATE,
        data: { email }
      })

      localStorage.setItem(`forgottenID`, data.requestID)
      setSecondStep(true)
      setErrorBlockEmail(false)
    } catch (err) {
      console.log(err)
      setErrorBlockEmail(true)
    }
  }

  const finishUpdateHandler = async () => {
    try {
      await axios.request({
        method: `PUT`,
        url: BASE_URL + Url.UPDATE_PASSWORD,
        data: {
          requestID: localStorage.getItem(`forgottenID`),
          newPassword,
          code: emailToken
        }
      })

      setForgetPassword(false)
      setSecondStep(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <form
      onSubmit={forgetPassword ? forgetPassHandler : loginHandler}
      className="auth">
      {isLoading && <Spinner />}
      <h3 className="auth__header">Форма входа для наставника</h3>
      {
        forgetPassword && !secondStep &&
        <label className="auth__label">
          <span className="auth__label-text">Введите почту</span>
          <input
            autoComplete="email"
            className="auth__input"
            value={email}
            onInput={({ target }) => setEmail(target.value)}
            type="email" />
        </label>
      }
      {
        !secondStep && !forgetPassword &&
        <>
          <label className="auth__label">
            <span className="auth__label-text">Введите логин</span>
            <input
              autoComplete="username"
              className="auth__input"
              value={username}
              onInput={({ target }) => setUsername(target.value)}
              type="login" />
          </label>
          <label className="auth__label">
            <span className="auth__label-text">Введите пароль</span>
            <input
              autoComplete="password"
              className="auth__input"
              value={password}
              onInput={({ target }) => setPassword(target?.value.replaceAll(" ", ""))}
              type={isShow ? "text": "password"}/>
            <img onClick={() => setIsShow((prevState) => !prevState)}
                 src={isShow ? hide : show}
                 className="password-field"/>
          </label>
        </>
      }
      <button
        type='button'
        className='button auth__forgot'
        onClick={() => {
          setErrorBlock(false)
          setErrorBlockEmail(false)
          setForgetPassword(!forgetPassword)
        }}
      >{
          !forgetPassword
            ? `забыли пароль ?`
            : `назад`}
      </button>

      {
        secondStep &&
        <div style={{ display: `flex`, flexDirection: `column`, alignContent: `center` }}>
          <label className="auth__label">
            <span className="auth__label-text">Новый пароль</span>
            <input
              autoComplete="new-password"
              className="auth__input"
              value={newPassword}
              onInput={({ target }) => setNewPassword(target.value)}
              type="password" />
          </label>
          <label className="auth__label">
            <span className="auth__label-text">Повторите новый пароль</span>
            <input
              autoComplete="new-password"
              className="auth__input"
              value={newRepeatedPassword}
              onInput={({ target }) => setNewRepeatedPassword(target.value)}
              type="password" />
          </label>
          <label className="auth__label">
            <span className="auth__label-text">Код подтверждения с почты</span>
            <input
              className="auth__input"
              value={emailToken}
              onInput={({ target }) => setEmailToken(target.value)}
              type="text" />
          </label>
          <button
            type='button'
            className='button'
            onClick={finishUpdateHandler}
          >Обновить</button>
        </div>
      }
      {errorBlock
        ? <span className="auth__error">Неверный логин или пароль</span>
        : null}
      {forgetPassword && errorBlockEmail
          ? <span className="auth__error">Попробуйте другую почту</span>
          : null}
      {
        !secondStep && <button className="auth__submit button">{
          forgetPassword
            ? `Отправить`
            : `Войти`
        }</button>
      }

    </form>
  )
}

export default MentorAuth;
