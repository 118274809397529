import React, { useEffect, useState } from 'react';

import './main.scss';
import Form from '../form/form';
import { useDispatch, useSelector } from 'react-redux';
import InviteScreen from '../invite-screen/invite-screen';
import { openRegistry } from '../../store/actions';
import axios from 'axios';
import Spinner from '../spinner/spinner';
import { BASE_OPEN_URL, BASE_URL, Url } from '../../const';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Copy from '../../../public/img/copy.png';

const getTelegramBotLink = () => {
  const hostURI = window.location.host
  if (hostURI.includes("bishkek")) {
    return `terramentros_manager_bishkek_bot`
  }
  if (hostURI.includes(`samara`)) {
    return `terramentros_manager_samara_bot`
  }
  if (hostURI.includes(`kazan`)) {
    return `terramentros_manager_kazan_bot`
  }
  return `TerraMentorsManager_bot`

}

const Main = ({ isLeader = false }) => {
  const dispatch = useDispatch();


  // const tgVerifyLink = `https://t.me/scum_terra_bot`
  const tgVerifyLink = `https://t.me/${getTelegramBotLink()}`
  const invitationCode = useSelector(({ AUTH }) => AUTH.invitationCode);
  const openDate = useSelector(({ AUTH }) => AUTH.openDate);
  const [loading, setLoading] = useState(true);

  const [countdown, setCountdown] = useState(false);

  // const [openForm, setOpenForm] = useState(false)
  const [openForm, setOpenForm] = useState(`validate`)
  const [TGCode, setTGCode] = useState(null);
  const [isValidated, setIsValidated] = useState(false);


  const validateTGHandler = async () => {
    const { data } = await axios.request({
      method: `POST`,
      url: BASE_URL + Url.VERIFY
    })
    const { code, registrationID } = data;

    localStorage.setItem(`registrationID`, registrationID)
    localStorage.setItem(`code`, code)
    setTGCode(code)
  }

  useEffect(() => {
    axios.get(`${BASE_OPEN_URL}/open`)
      .then(({ data: { open, start } }) => {

        if (open) {
          if (isLeader) {
            setCountdown(1632128400000000)
          } else {
            dispatch(openRegistry());
            setCountdown(false)
            setOpenForm(`validated`)
          }

        } else if (!open) {
          setCountdown(start)
        }
      })
      .then(() => setLoading(false));

  }, []);

  useEffect(() => {

    const checkValidation = async () => {
      const { data } = await axios.request({
        method: `POST`,
        url: BASE_URL + Url.CHECK_VERIFY,
        data: {
          registrationID: localStorage.getItem(`registrationID`)
        }
      })

      const { ready } = data;

      if (ready) {
        setIsValidated(ready)
        setOpenForm(true)
      }
    };

    let intervalID;
    const pending = () => {
      intervalID = setInterval(checkValidation, 1000);
    }
    if (openForm === `validated`) {
      pending()
    }

    if (openForm === true) {
      clearInterval(intervalID)
    }

    return () => clearInterval(intervalID)
  }, [openForm]);

  if (loading) {
    return <Spinner />
  };


  return (
    <div className="main">
      {
        (invitationCode || openDate) && openForm === true
          ? <Form />
          : countdown && openForm === `validate` && <InviteScreen countdown={countdown} setOpenForm={setOpenForm} />
      }

      {
        openForm === `validated` &&
        <>
          {
            !TGCode &&
            <div className='main-verify'>

              <p>
                Здравствуйте!
                Вы начали регистрацию на новый поток Наставничества Терра!
                Верифицируйтесь в телеграм, чтобы продолжить выбор Наставника.
                Конечный шаг - попадание в чат к Наставнику в Телеграм, поэтому регистрация проходит через эту платформу.
              </p>

              <button
                type='button'
                className='main-verify__button'
                onClick={validateTGHandler}
              >
                Верифицировать телеграм
              </button>
            </div>
          }
          {TGCode && <div className='main__verify'>
            <span >Вставьте этот код в телеграм:</span>
            <strong>{TGCode}</strong>
            <CopyToClipboard text={TGCode}>
              <button
                className="telegram__action"
                type="button">
              </button>
            </CopyToClipboard>
            <span>скопировать код</span>
            <a className='telegram__link-verify' href={tgVerifyLink} target="_blank">Перейти в бота</a>
          </div>
          }
        </>
      }


    </div>
  )
}

export default Main;