import React, { useEffect, useRef, useState } from 'react';
import './leader.scss';
import ModalVideo from 'react-modal-video'
import { useDispatch } from 'react-redux';
import { changeEducationType } from '../../store/actions';
import { useSelector } from 'react-redux';

const getImgUrl = () => {
  const host = window.location.host;
  if (host.includes("start") || host.includes("launch") || host.includes("breakthrough")) {
    return `${host.split(".")[0]}.`;
  }
};

const getFullUrl = (urlphoto, type) => {
  if (urlphoto.includes("://") && type) {
    let urlEls = urlphoto.split("://");
    return `${urlEls[0]}://${type}${urlEls[1]}`;
  }
  return urlphoto;
};

const Leader = ({
  setMentor,
  mentor,
  handleMentorChoice,
  setUserEducationType,
  userDirectionType }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [mentorDescription, setMentorDescription] = useState(false);
  const [mentorView, setMentorView] = useState(false);

  const userEducationType = useSelector(({ LEADERS }) => LEADERS.educationType)
  const certifiedAccess = useSelector(({ LEADERS }) => LEADERS.certifiedAccess)

  const btnRef = useRef();
  const {
    ID,
    fullName,
    photoURL,
    employmentList,
    videoURL,
    canRegister,
    competenceList,
    certified,
    directionList,
    educationList,
    cityName
  } = mentor;
  let videoId;

  if (videoURL) {
    if (videoURL.includes(`v=`)) {
      videoId = videoURL.split('v=')[1];
      // console.log(videoId)
      const ampersandPosition = videoId.indexOf('&');

      if (ampersandPosition != -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
    }
  }

  const choseMentorHandler = (target) => {
    setMentor(ID)
    handleMentorChoice()
  }

  const showVideo = () => {
    document.body.style.overflow = `hidden`;
    const overlayItems = document.querySelectorAll(`.leader__name`)
    overlayItems.forEach((item) => item.style.zIndex = 1)
    setOpen(true);
    setMentorView(true);
  }

  const hideVideo = () => {
    console.log(`heere`)
    const overlayItems = document.querySelectorAll(`.leader__name`)
    overlayItems.forEach((item) => item.style.zIndex = 2000)
    document.body.style.overflow = `auto`;
    setOpen(false);
    setMentorView(false);
  }

  const isDisabled = () => {

    if (!certifiedAccess && certified) {
      return true
    }

    if (!canRegister) {
      return true
    }

    if (userEducationType === 1 && !educationList.includes(`Офлайн`)) {
      return true
    }

    if (userEducationType === 2 && !educationList.includes(`Онлайн`)) {
      return true
    }

  //    if (directionList) {
  //      if (directionList.includes(`Прорыв`) && userDirectionType !== 1) {
  //        return true
  //     }
    //      if (directionList.includes(`Запуск`) && userDirectionType !== 2) {
   //      return true
   //  }
   // if (directionList.includes(`Старт`) && userDirectionType !== 3) {
  //  return true
  // }
 // }
    return false
  }

  return (
    <div className="leader" style={{ zIndex: `${mentorView ? `1` : `0`}` }}>
      <img
        className="leader__img"
        // src={photoURL}
        src={getFullUrl(photoURL, getImgUrl())}
        alt="1"
        width="130"
        height="175" />
      <div className="leader__wrapper">
        <span className="leader__name">{fullName}</span>
        <span className="leader__name">Список направлений: </span>
        <ul>
          {directionList &&
            directionList.map((item, idx) => {
              return (
                <li key={idx} style={{ fontSize: `15px` }}>{item}</li>
              )
            })}
        </ul>

        <span className="leader__name">Список форматов: </span>
        <ul>
          {educationList &&
            educationList.map((item, idx) => {
              return (
                <li key={idx} style={{ fontSize: `15px` }}>{item}</li>
              )
            })}
        </ul>

        <span className="leader__name">Город: </span>
        <ul>
          {cityName}
        </ul>

        {certified
          ? <>
            <span className="leader__name"><strong>Атестованный наставник</strong></span>
            <div className='leader__certy'>
              {fullName} обучает только лидеров Терры.<br></br>
              Если ты не в команде лидеров Терры - выбери другого наставника.<br></br>
              Если хочешь стать частью команды лидеров Терры к следующему потоку, пройди по ссылке:
              <a href='https://leaderofterra.bitrix24.site/' target="_blank" rel="noreferer"> Лидеры</a>
            </div>
          </>
          : null}

        {videoURL
          ? <span className="leader__presentation">
            Презентация наставника:
            <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId ? videoId : null} onClose={() => hideVideo()} />
            <button ref={btnRef} type="button" className="leader__video" onClick={() => showVideo()} >Посмотреть презентацию</button>
          </span>
          : <span className="leader__presentation leader__presentation--no-video">Видео по наставнику не добавлено</span>
        }

        <span className="leader__text">Ниши:</span>
        {
          employmentList && employmentList.map((employment, idx) => {
            return (
              <span key={idx} className="leader__text">{employment}</span>
            )
          })
        }


        <button
          onClick={() => mentorDescription ? setMentorDescription(false) : setMentorDescription(true)}
          type="button"
          className="button leader__more-info">
          {
            mentorDescription
              ? `Скрыть`
              : `Подробнее`
          }
        </button>
        {
          mentorDescription
            ? <span className="leader__presentation leader__presentation--text">
              <h3>Компетенции:</h3>
              <ol>
                {
                  competenceList
                    ? competenceList.map((competence, index) => {
                      return (
                        <li key={index}>{competence}</li>
                      )
                    })
                    : null
                }
              </ol>
            </span>
            : null
        }
        <button
          id={ID}
          value={fullName}
          disabled={isDisabled()}
          onClick={({ target }) => choseMentorHandler(target)}
          type="button"
          className="button leader__button"
        >
          Выбрать наставника
        </button>
        {
          canRegister
            ? null
            : <span className="leader__disabled">К сожалению запись к этому наставнику закрыта</span>
        }

      </div>
    </div>
  )
}

export default Leader;
