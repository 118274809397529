import React from 'react';
import NextButton from '../next-button/next-button';
import ReturnButton from '../return-button/return-button';
import SubmitButton from '../submit-button/submit-button';

import './buttons-block.scss';

const ButtonsBlock = ({
  isAgreed,
  setIsAgreed,
  handleNextPage,
  isNextDisabled,
  isSubmitDisabled,
  isNextShowed,
  handlePreviousPage,
  isReturnDisabled,
  isFailedRegistration,
  isLoading,
  isBackButton }) => {

  return (
    <div className="container__inner next">
      {isBackButton && <ReturnButton
        handlePreviousPage={handlePreviousPage}
        isReturnDisabled={isReturnDisabled}
      />}
      {isNextShowed ? <NextButton
        handleNextPage={handleNextPage}
        isNextDisabled={isNextDisabled}
        isAgreed={isAgreed}
        setIsAgreed={setIsAgreed} /> : null}
      {isSubmitDisabled ? null : <SubmitButton
        isSubmitDisabled={isSubmitDisabled}
        isFailedRegistration={isFailedRegistration}
        isLoading={isLoading} />}
    </div>
  )
};

export default ButtonsBlock;
