import { saveAs } from 'file-saver';

export const getLimit = (page, limit, totalLen) => {
  let maxLimit = page * limit;

  if (maxLimit > totalLen) {
    maxLimit = totalLen;
  }

  return maxLimit
}

export const adaptToDropdown = (object) => {
  const adaptedItem = Object.assign(
    {},
    object,
    {
      value: object.ID,
      label: object.name,
    }
  );

  delete adaptedItem.ID;
  delete adaptedItem.name;

  return adaptedItem;
}


export const handleFiles = (files) => {
  for (let i = 0; i < files.length; i++) {
    let file = files[i];

    if (!file.type.startsWith('image/')) { continue }

    let img = document.createElement("img");
    img.classList.add("obj");
    img.file = file;
    // preview.appendChild(img);

    let reader = new FileReader();
    reader.onload = (function (aImg) { return function (e) { aImg.src = e.target.result; }; })(img);
    reader.readAsDataURL(file);
  }
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const createDownloadLink = (data) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  saveAs(url, 'report.xlsx');
};