import axios from "axios";
import { useState } from "react";
import browserHistory from "../../browser-history";
import { BASE_URL, Path } from "../../const";
import Spinner from "../spinner/spinner";
import UserSelect from "../user-select/user-select";

const ModeratorUser = ({ user, setUsersTrigger }) => {
  const [studentState, setStudentState] = useState(user.registration_stateName);
  const [studentStateId, setStudentStateId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmitStudent = async (evt) => {
    evt.preventDefault();

    setIsLoading(true);
    try {
      await axios.get(
        `${BASE_URL}/manager/update_user_state?mentorID=${user.mentorData.ID}&userID=${user.ID}&stateID=${studentStateId}`
      );
      setUsersTrigger((state) => !state);
    } catch {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleOptionChange = (target) => {
    setStudentState(target.value);

    if (target[1].selected) {
      setStudentStateId(target[1].id);
    }
    if (target[2].selected) {
      setStudentStateId(target[2].id);
    }
    if (target[3].selected) {
      setStudentStateId(target[3].id);
    }
    if (target[4].selected) {
      setStudentStateId(target[4].id);
    }

  };

  return (
    <div className="user-block" key={user.ID}>
      <span>Имя: {user.fullName}</span>
      <span>Telegram ID: {user.TGID}</span>
      <span>Telegram: {user.tgUsername}</span>
      <span>Создано: {new Date(user.createdAt * 1000).toDateString()}</span>
      <span>Направление: {user.directionTypeName}</span>
      <span>Тип обучения: {user.educationTypeNmae}</span>
      <span>Email: {user.email}</span>
      <span>Ниша: {user.employment}</span>
      <span>Имя ментора: {user.mentorData.fullName}</span>
      <span>ID ментора: {user.mentorData.ID}</span>
      <span>Количество пройденных наставничеств: {user.mentoringCount}</span>
      <span>Месячный доход: {user.monthlyIncome}</span>
      <span>Номер телефона: {user.phoneNumber}</span>
      <span>Статус: {user.registration_stateName}</span>
      <div onChange={() => setIsError(false)}>
        {isLoading ? (
          <span>обновляем...</span>
        ) : (
          <UserSelect
            handleSubmitStudent={handleSubmitStudent}
            handleOptionChange={handleOptionChange}
            isModer={true}
          />
        )}
        {isError && <span>произошла ошибка</span>}
      </div>
    </div>
  );
};

export default ModeratorUser;
