import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from '../loading-screen/loading-screen';

const ErrorScreen = () => {

  const errorMessage = useSelector(({ LEADERS }) => LEADERS.errorMessage);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    if (errorMessage.includes(`вы уже находитесь на`)) {
      setIsFailed(true)
    } else {
      setIsFailed(false)
    }
  }, [errorMessage])

  if (!errorMessage) {
    return (
      <LoadingScreen />
    );
  }


  return (
    <div className="container__inner">
      {
        isFailed
          ? <h3 className="registration__header">{errorMessage}</h3>
          : <h3 className="registration__header">Поздравляем! Вы успешно зарегистрировались к {errorMessage}.
          Когда наставник примет решение брать вас в группу или нет - в телеграм придёт уведомление и вы получите ссылку на чат <br/>
          {/* Вы также можете зарегистрироваться на 2 оффлайн-школы. Выбирайте школы на сайте: <a href="https://terra-schools.ru">terra-schools.ru</a> */}
        </h3>
      }

    </div>
  )
};

export default ErrorScreen;
