import React, { useEffect, useRef, useState } from 'react';
import { repeationData } from '../../const';

import './styled-radio-container.scss';

const RadioButton = ({
  value,
  description,
  handleRadio,
  handleActiveRadio,
  userMentoringCount,
}) => {

  const circleRef = useRef();

  useEffect(() => {
    if (circleRef.current) {
      const circleStyle = circleRef.current.style;
      if (window.navigator.vendor === ``) {
        circleStyle.top = `48px`
        circleStyle.right = `0`
        if (window.innerWidth < 1024) {
          circleStyle.top = `44px`
          circleStyle.right = `34%`
        }

        if (window.innerWidth > 869 && window.innerWidth < 1024) {
          circleStyle.right = `44%`
        }
      }
      if (window.navigator.vendor === `Apple Computer, Inc.`) {
        circleStyle.top = `70px`

        if (window.innerWidth < 1024) {
          circleStyle.top = `42px`
          circleStyle.right = `30%`
        }

        if (window.innerWidth > 689 && window.innerWidth < 1024) {
          circleStyle.right = `43%`
        }
      }

    }
  }, [])

  return (
    <>
      <input
        onChange={(evt) => handleActiveRadio(evt)}
        onInput={({ target }) => handleRadio(target)}
        className="rating__input"
        id={`${value}`}
        type="radio"
        name="repeation"
        value={value}
        checked={(value == userMentoringCount ? true : null) || ''}
      />
      <label
        className="rating__label rating__income"
        htmlFor={`${value}`}>
        {description}
        <span
          ref={circleRef}
          className="rating__label-circle rating__label-circle--styled"></span>
      </label>
    </>
  )
}

const StyledRadioContainer = ({ listData, title, onRadioChange, userMentoringCount }) => {
  const radioListRef = useRef();
  const progressRef = useRef();
  const [progressBar, setProgress] = useState(0);
  const [isActive, setActive] = useState('');

  const getProgressClass = () => listData === repeationData ? `form__radio-progress` : `form__radio-progress form__radio-progress--income`;

  const handleRadio = (target) => {

    const inputsList = (radioListRef.current).querySelectorAll(`.rating__input`);
    const circlesList = (radioListRef.current).querySelectorAll(`.rating__label-circle`);

    const inputsListArr = Array.from(inputsList);
    const circlesListArr = Array.from(circlesList);
    const white = `#fff`;
    const blue = `#299DC6`;

    const setCirclesColor = (circle, index) => {

      if (circle.checked) {
        if (index === 0) {
          circlesList[0].style.backgroundColor = blue;
          circlesListArr.slice(1).forEach((circle) => {
            return circle.style.backgroundColor = white;
          });
        } else if (index === 1) {
          circlesListArr.slice(0, 2).forEach((circle) => {
            return circle.style.backgroundColor = blue;
          });
          circlesListArr.slice(2).forEach((circle) => {
            return circle.style.backgroundColor = white;
          });
        } else if (index === 2) {
          circlesListArr.slice(0, 3).forEach((circle) => {
            return circle.style.backgroundColor = blue;
          });
          circlesListArr.slice(3).forEach((circle) => {
            return circle.style.backgroundColor = white;
          });
        } else if (index === 3) {
          circlesListArr.slice(0, 4).forEach((circle) => {
            return circle.style.backgroundColor = blue;
          });
          circlesListArr.slice(4).forEach((circle) => {
            return circle.style.backgroundColor = white;
          });
        } else if (index === 4) {
          circlesListArr.forEach((circle) => {
            return circle.style.backgroundColor = blue;
          });
        }
      }
    }

    inputsListArr.findIndex(setCirclesColor)

    switch (target || target.value || target.innerText) {
      case '0':
        return setProgress(0)
      case '1':
        return setProgress(25)
      case '2':
        return setProgress(50)
      case '3':
        return setProgress(75)
      case '4':
        return setProgress(100)
      default:
        break;
    }
  };

  useEffect(() => {
    handleRadio(userMentoringCount)
  })

  const handleActiveRadio = (evt) => {
    onRadioChange(evt.target.value)

    if (userMentoringCount == evt.target.value) {
      return (
        setActive(true),
        handleRadio(evt.target)
      )
    }
    return setActive(null)
  }

  return (
    <div className="container__inner form__radio-container">
      <h3 className="radio__header">{title}</h3>
      <div 
      ref={radioListRef}
      className="radio__wrapper radio__wrapper--repeation">
        {
          listData.map(({ name, value, description }) => {
            return (
              <RadioButton
                userMentoringCount={userMentoringCount}
                handleActiveRadio={handleActiveRadio}
                isActive={isActive}
                key={value}
                listData={listData}
                onRadioChange={onRadioChange}
                handleRadio={handleRadio}
                value={value}
                name={name}
                description={description} />
            )
          })
        }
        <div
          ref={progressRef}
          className={getProgressClass()}
          style={{ background: `linear-gradient(to right, #299DC6 ${progressBar}%, white ${progressBar}%)` }}>
        </div>
      </div>
    </div>
  )
}

export default StyledRadioContainer;
