import React, { useEffect, useState } from 'react';
import axios from "axios";

import { BASE_URL, Path, Url } from '../../const';

import './student.scss';
import UserSelect from '../user-select/user-select';
import browserHistory from '../../browser-history';

const Student = ({ student, token, setUpdateStudentsList }) => {

  const {
    fullName,
    employment,
    monthlyIncome,
    phone,
    email,
    tgUsername,
    educationTypeName,
    directionTypeName,
    stateName,
    ID,
    userID,
    stateID,
    phoneNumber,
    canUpdateStates } = student;

  const [studentState, setStudentState] = useState(stateName);
  const [studentStateId, setStudentStateId] = useState(1);
  const [stateless, setStateless] = useState(stateID);
  const [userStatus, setUserStatus] = useState(``);
  const [submitUser, setSubmitUser] = useState(false);
  const [warningMessage, setWarningMessage] = useState(``);


  useEffect(() => {
    setStudentState(stateName)
    setStudentStateId(1)

  }, [student])

  const handleSubmitStudent = (evt) => {

    const data = {
      userRegistrationID: +ID,
      userID: +userID,
      stateID: +studentStateId
    }
    evt.preventDefault();
    setStateless(0);

    axios
      .post(`${BASE_URL}/mentor/update_registration_state`, data, { 'headers': { 'Authorization': token } })
      .catch(() => {
        localStorage.removeItem(`token`)
        browserHistory.push(Path.AUTHORIZATION)
      })
  }

  const handleOptionChange = (target) => {
    setStudentState(target.value);

    if (target[0].selected) {
      setStudentStateId(target[0].id);

    }
    if (target[1].selected) {
      setStudentStateId(target[1].id);
      setWarningMessage(`test 2`)
    }
    if (target[2].selected) {
      setStudentStateId(target[2].id);
      setWarningMessage(`test 3`)
    }
    if (target[3].selected) {
      setStudentStateId(target[3].id);
      setWarningMessage(`test 4`)
    }
  }


  const submitUserHandler = async () => {
    try {
      await axios.request({
        method: `PUT`,
        url: BASE_URL + Url.UPDATE_USER_STATE,
        data: {
          userID: ID,
          stateID: +userStatus,
        },
        headers: {
          Authorization: token
        }
      })
      setUpdateStudentsList(state => !state)
    } catch (err) {
      console.log(err)
      localStorage.removeItem(`token`)
      browserHistory.push(Path.AUTHORIZATION)
    }
    setSubmitUser(false)
  }

  return (
    <li className="student">

      <h3 className="student__name">{fullName}</h3>
      <p className="student__description student__business">Бизнес: {employment}</p>
      <p className="student__description student__income">Выручка в месяц: {monthlyIncome}</p>
      <p className="student__description student__description student__phone">Телефон: {phoneNumber}</p>
      <p className="student__description student__email">Email: {email}</p>
      <p className="student__description student__telegram">Ник в Telegram: {tgUsername}</p>
      <p className="student__description student__education-type">Тип обучения: {educationTypeName}</p>
      <p className="student__description student__direction-type">Направление: {directionTypeName}</p>
      <p className="student__description student__current-state">Состояние: {studentState}</p>

      {stateless === 1
        ? <UserSelect handleSubmitStudent={handleSubmitStudent} handleOptionChange={handleOptionChange} />
        : null
      }


      {
        canUpdateStates &&
        <>
          <h3>Изменить статус</h3>
          <select
            value={userStatus}
            onChange={(({ target }) => {
              const selectedStatus = +target.selectedOptions[0].value
              setUserStatus(selectedStatus)

              switch (selectedStatus) {
                case 3:
                  setWarningMessage(`Вы не прошли отбор в группу к наставнику. Перейдите на сайт https://terramentorsmanager.ru/ и выберите другого наставника из доступного списка. Эта опция вам доступна только один раз.`)
                  break
                case 2:
                  setWarningMessage(`Поздравляем! Вы зарегистрированы на новый поток наставничества к наставнику`)
                  break
                case 4:
                  setWarningMessage(`Вы не прошли отбор в группу к наставнику, так как не выполнили тестовое задание. В этом потоке вам больше не доступен выбор наставника - ожидайте набор на новый поток через два месяца.`)
                default:
                  break
              }
              setSubmitUser(true)
            })}
          >
            <option value=''>выбрать</option>
            {canUpdateStates.map(({ displayName, stateID }) => {
              return (
                <option key={stateID} value={stateID}>{displayName}</option>
              )
            })
            }

          </select>
        </>
      }

      {
        submitUser &&
        <div className='modal__user-status'>
          <div className='modal__user-status-inner'>
            <button className='close' onClick={() => setSubmitUser(false)}>X</button>
            Текст, который придет участнику в телеграм:
            <strong>{warningMessage}</strong>
            <button
              type='button'
              className='button'
              onClick={submitUserHandler}
            >Подтвердить</button>
          </div>
        </div>
      }
    </li>
  )
};

export default Student;