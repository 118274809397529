import {createAction} from '@reduxjs/toolkit';

export const ActionType = {
  FETCH_MENTORS: `FETCH_MENTORS`,
  FETCH_STUDENTS: `FETCH_STUDENTS`,
  GET_TELEGRAM_CODE: `GET_TELEGRAM_CODE`,
  REDIRECT_TO_ROUTE: `REDIRECT_TO_ROUTE`,
  REQUIRED_AUTHORIZATION: `REQUIRED_AUTHORIZATION`,
  GET_TOKEN: `GET_TOKEN`,
  GET_ERROR_MESSAGE: `GET_ERROR_MESSAGE`,
  FETCH_EMPOYMENTS: `FETCH_EMPOYMENTS`,
  CHANGE_EDUCATION_TYPE: `CHANGE_EDUCATION_TYPE`,
  SET_INVITATION_CODE: `/SET_INVITATION_CODE`,
  OPEN_REGISTRY: `OPEN_REGISTRY`,
  MODERATOR_AUTH: `MODERATOR_AUTH`,
  SET_EDUCATION_LIST: `SET_EDUCATION_LIST`,
  SET_CERTIFIED_ACCESS: `SET_CERTIFIED_ACCESS`,
  SET_IS_EARLY_REGISTRATION: `SET_IS_EARLY_REGISTRATION`,
}


export const setInvitationCode = createAction(ActionType.SET_INVITATION_CODE, (code) => ({
  payload: code
}));

export const openRegistry = createAction(ActionType.OPEN_REGISTRY);

export const getTelegramCode = createAction(ActionType.GET_TELEGRAM_CODE, (code) => ({
  payload: code
}));

export const fetchMentors = createAction(ActionType.FETCH_MENTORS, (mentors) => ({
  payload: mentors
}));

export const fetchStudents = createAction(ActionType.FETCH_STUDENTS, (students) => ({
  payload: students
}));

export const redirectToRoute = createAction(ActionType.REDIRECT_TO_ROUTE, (url) => ({
  payload: url
}));

export const requireAuthorization = createAction(ActionType.REQUIRED_AUTHORIZATION, (status) => ({
  payload: status
}));

export const getToken = createAction(ActionType.GET_TOKEN, (token) => ({
  payload: token
}));

export const getErrorMessage = createAction(ActionType.GET_ERROR_MESSAGE, (message) => ({
  payload: message
}));

export const fetchEmployments = createAction(ActionType.FETCH_EMPOYMENTS, (employmentsList) => ({
  payload: employmentsList
}));

export const changeEducationType = createAction(ActionType.CHANGE_EDUCATION_TYPE, (educationType) => ({
  payload: educationType
}));

export const moderatorAuth = createAction(ActionType.MODERATOR_AUTH, (isAuth) => ({
  payload: isAuth
}));

export const setEducationList = createAction(ActionType.SET_EDUCATION_LIST, (list) => ({
  payload: list
}));

export const setCertifiedAccess = createAction(ActionType.SET_CERTIFIED_ACCESS, (isCertified) => ({
  payload: isCertified
}));

export const setIsEarlyRegistration = createAction(ActionType.SET_IS_EARLY_REGISTRATION, (isEarly) => ({
  payload: isEarly
}));

