import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BASE_URL, Url } from "../../const";
import { setCertifiedAccess, setInvitationCode, setIsEarlyRegistration } from "../../store/actions";
import CountDown from "../countdown/countdown";
import { v4 as uuidv4 } from 'uuid';

const scamToken = uuidv4()
console.log(`uid`)
const InviteScreen = ({ countdown, setOpenForm }) => {

  const dispatch = useDispatch();
  const [inviteCode, setInviteCode] = useState(``);
  const [isError, setIsError] = useState(null);

  const sendCodeHandler = () => {
    setIsError(null)
    axios.request({
      method: `GET`,
      url: BASE_URL + Url.GET_EARLY_ACCESS_TOKEN_INFO + '?token=' + inviteCode,
    })
      .then(({ data }) => {
        if (data.exists) {
          dispatch(setInvitationCode(inviteCode))
          sessionStorage.setItem(`inviteCode`, inviteCode)
          dispatch(setCertifiedAccess(data.certifiedAccess))
          dispatch(setIsEarlyRegistration(true))
          setOpenForm("validated")
        } else {
          setIsError(`Токен указан неверно`)
        }
      })
      .catch((err) => setIsError(`Произошла ошибка, попробуйте ещё раз`))
  }



  return (
    <div style={{
      display: `flex`,
      flexDirection: `column`,
      width: `300px`,
      alignItems: `center`,
      background: `#fff`,
      padding: `20px`,
      marginTop: `20px`,
      borderRadius: `10px`,
      gap: `30px`
    }}>
      <div>
        {/* <h3>Регистрация для онлайн участников откроется через</h3>
        <CountDown expireAt={countdown} /> */}
      </div>
      <label
        style={{
          display: `flex`,
          flexDirection: `column`,
          fontWeight: `600`
        }}
      >
        <h3>Регистрация для офлайн участников нового потока наставничества.</h3>
        Код с браслета для офлайн участников
        <input
          placeholder="ваш код"
          style={{
            width: `200px`,
            height: `40px`,
            border: `1px solid grey`,
            paddingLeft: `10px`,
            marginTop: `5px`
          }}
          type="text"
          value={inviteCode}
          onInput={({ target: { value } }) => setInviteCode(value)}
        />
      </label>
      <button
        type="button"
        style={{
          backgroundColor: `green`,
          borderRadius: `5px`,
          color: `#fff`,
          cursor: `pointer`,
          width: `200px`,
          height: `40px`
        }}
        onClick={sendCodeHandler}
      >
        Отправить
      </button>
      {isError && <span style={{ color: `red` }}>{isError}</span>}
    </div>
  )
};

export default InviteScreen;
