import { useEffect, useState } from "react";
import { BASE_URL, Path, Url } from "../../const";

import { useFetchData } from "../../hooks/use-fetch-data";
import { getLimit } from "../../utils";
import ModeratorUser from "../moderator-user/moderator-user";
import Pagination from "../pagination/pagination";
import Spinner from "../spinner/spinner";
import "./moderator-users.scss";

const ModeratorUsers = ({
  usersFilter,
  setUsersFilter,
  users,
  isLoading,
  setUsersTrigger,
}) => {
  const [page, setPage] = useState(1);
  const { data, totalLen } = users;
  const { search, mentorName, limit, offset } = usersFilter;

  const pageHandler = ({ target }) => {
    const { value } = target;

    if ((value - 1) * limit < totalLen && +value && !isLoading) {
      setPage(+value);
      setUsersFilter((state) => ({
        ...state,
        offset: (value - 1) * limit,
      }));
    }
  };

  const searchHandler = ({ target }) => {
    setPage(1);
    setUsersFilter((state) => ({
      ...state,
      search: target.value ? target.value : null,
      offset: 0,
    }));
  };

  const searchMentorHandler = ({ target }) => {
    setPage(1);
    setUsersFilter((state) => ({
      ...state,
      mentorName: target.value ? target.value : null,
      offset: 0,
    }));
  };

  const limitHandler = ({ target }) => {
    setPage(1);
    setUsersFilter((state) => ({
      ...state,
      limit: +target.value,
      offset: 0,
    }));
  };

  return (
    <div className="moderator-users">
      {isLoading && (
        <div className="spinner-absolute">
          <Spinner />
        </div>
      )}
      <div className="users-filters">
        <Pagination
          length={totalLen}
          limit={limit}
          page={page}
          pageHandler={pageHandler}
          limitHandler={limitHandler}
        />
        <label>
          <span>Поиск по имени/тг/почта: </span>
          <input
            type="text"
            value={search ? search : ``}
            onChange={searchHandler}
          />
        </label>
        <label>
          <span>Поиск по наставнику: </span>
          <input
            type="text"
            value={mentorName ? mentorName : ``}
            onChange={searchMentorHandler}
          />
        </label>
      </div>

      <div className="moderator-users-list">
        {data &&
          data.length &&
          data.map((user) => {
            return (
              <ModeratorUser
                key={user.ID}
                user={user}
                setUsersTrigger={setUsersTrigger}
              />
            );
          })}
      </div>
      <div>
        {" "}
        {totalLen ? page * limit - limit + 1 : 0} -{" "}
        {getLimit(page, limit, totalLen)} Из {totalLen}
      </div>
    </div>
  );
};

export default ModeratorUsers;
