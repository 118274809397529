import { Link } from "react-router-dom";
import { Path } from "../../const";

import './personal.scss';

const Personal = () => {
  return (
    <div className="personal">
      <div 
      style={{
        marginBottom: `20px`
      }}>
        <h1>СОГЛАСИЕ</h1>
        <p>посетителя сайта на обработку персональных данных</p>
        <p>
          Настоящим свободно, своей волей и в своем интересе даю согласие Автономной некоммерческой организации центр по обеспечению досуга и развития личности «Территория развития личности» (далее – АНО Терра) на автоматизированную и неавтоматизированную обработку моих персональных данных, в том числе с использованием интернет-сервисов Google analytics, Яндекс.Метрика, LiveInternet, Рейтинг Mail.ru, Google Doubleclick, сервисов предиктивного обзвона для повышения производительности колл-центров  и подобных аналогичных ресурсов в соответствии со следующим перечнем:
        </p>
        <p>

          – фамилия, имя, отчество, фотографию и иные личные данные;<br></br>
          – источник захода на сайт https://myterra.club/, https://terramentorsmanager.ru  (далее – Сайт) и информация поискового или рекламного запроса;<br></br>
          – данные о пользовательском устройстве (среди которых разрешение, версия и другие атрибуты, характеризующие пользовательское устройство);<br></br>
          – пользовательские клики, просмотры страниц, заполнения полей, показы и просмотры баннеров и видео;<br></br>
          – данные, характеризующие аудиторные сегменты;<br></br>
          – параметры сессии;<br></br>
          – данные о времени посещения;<br></br>
          – идентификатор пользователя, хранимый в cookie,<br></br>
          для целей повышения осведомленности посетителей Сайта о продуктах и услугах, предоставления релевантной рекламной информации и оптимизации рекламы.<br></br>
        </p>
        <p>
          АНО Терра вправе осуществлять обработку моих персональных данных следующими способами: любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных, осуществление рассылок и звонков с целью уведомления о деятельности АНО Терра.
        </p>
        <p>
          Настоящее согласие вступает в силу с момента его предоставления на Сайте АНО Терра и действует в течение сроков, установленных действующим законодательством РФ.
        </p>
        <p>
          Настоящее Согласие может быть отозвано в соответствии со ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» путем предоставления письменного обращения по адресу АНО Терра или направив обращение на адрес электронной почты terra.club.msk@gmail.com
        </p>
        <p>
          В случае отзыва настоящего Согласия АНО Терра вправе продолжить обработку указанных вами персональных данных без вашего согласия при наличии оснований, указанных в п.п.2-11 ч.1 ст.6, ч.2 ст.10 и ч.2 ст.11 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
        </p>
      </div>

      <Link style={{
        marginTop: `10px`,
        color: `#fff`,
        background: `blue`,
        padding: `15px`,
        borderRadius: `10px`
      }} to={Path.DEFAULT}>Назад</Link>
    </div >
  );
};

export default Personal;