import React from 'react';

const SimpleRadioButton = ({ value, name, description, id, setUserDirectionType, userDirectionType }) => {

  return (
    <label className="form__radio-button">
      <input
        onChange={({ target }) => setUserDirectionType(target.id)}
        id={id}
        type="radio"
        name={name}
        className="radio"
        value={value}
        checked={(id == userDirectionType ? true : null) || ''}
        required />
      <span
        className="form__radio-text form__radio-text--simple form__direction-button"> {description}</span>
    </label>
  )
}

const DirectionRadioContainer = ({ listData, title, setUserDirectionType, directionRef, userDirectionType }) => {

  return (
    <div
      ref={directionRef}
      id="direction"
      className="container__inner form__radio-container">
      <h3 className="radio__header">{title}</h3>
      <div className="radio__wrapper radio__wrapper--simple">
        {
          listData.map(({ name, value, description, id }) => {
            return (
              <SimpleRadioButton
                key={value}
                value={value}
                id={id}
                name={name}
                description={description}
                setUserDirectionType={setUserDirectionType}
                userDirectionType={userDirectionType}
                />
            )
          })
        }
      </div>
    </div>
  )
}

export default DirectionRadioContainer;

