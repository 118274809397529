import axios from "axios";
import { useState } from "react";
import { BASE_URL, Url } from "../../const";
import { createDownloadLink } from "../../utils";
import Spinner from "../spinner/spinner";
import Cookies from 'js-cookie'

import './moderator-main.scss';

const Id = {
    MENTOR: `MENTOR`,
    USER: `USER`,
};

const ModeratorMain = () => {
    const [mentorTokens, setMentorTokens] = useState({
        count: ``,
        certified: false
    })
    const [mentorTokensList, setMentorTokensList] = useState(null)

    const [userTokens, setUserTokens] = useState({
        count: ``,
        certified: false
    })
    const [userTokensList, setUserTokensList] = useState(null)

    const [verifyCodeInfo, setVerifyCodeInfo] = useState({
        code: ``,
        username: ``,
        codesList: [],
        noInfo: false,
    })


    const [isLoading, setIsLoading] = useState(false);

    const createTokens = async ({ target }) => {
        setIsLoading(true);
        const options = {
            method: `POST`,
            headers: {
                Authorization: Cookies.get(`moder_key`)
            },
            url: BASE_URL + (target.id === Id.MENTOR
                ? Url.CREATE_MENTOR_INVITE_TOKEN
                : Url.CREATE_USER_INVITE_TOKEN),
            data: target.id === Id.MENTOR
                ? mentorTokens
                : userTokens,
        }

        try {
            const { data } = await axios.request(options)
            if (target.id === Id.MENTOR) {
                setMentorTokensList(data)
                setMentorTokens(state => ({
                    ...state,
                    count: ``
                }))

            } else {
                setUserTokensList(data);
                setUserTokens(state => ({
                    ...state,
                    count: ``
                }))

            }
            setIsLoading(false)
        } catch {
            setIsLoading(false)
        }
    }

    const dataDownloadHandler = async ({ target }) => {
        try {
            const { data } = await axios.request({
                method: `GET`,
                headers: {
                    Authorization: Cookies.get(`moder_key`)
                },
                url: BASE_URL + (target.id === Id.MENTOR
                    ? Url.EXPORT_MENTOR_DATA
                    : Url.EXPORT_USER_DATA),
                responseType: 'blob'
            })

            createDownloadLink(data);
        } catch (err) {
            console.log(`err `, err)
        }
    }
    const dataDownloadHandlerScam = async ({ target }) => {
        try {
            const { data } = await axios.request({
                method: `GET`,
                headers: {
                    Authorization: Cookies.get(`moder_key`)
                },
                url: "http://135.181.3.26" + (target.id === Id.MENTOR
                    ? Url.EXPORT_MENTOR_DATA
                    : Url.EXPORT_USER_DATA),
                responseType: 'blob',
                mode: 'cors'
            })

            createDownloadLink(data);
        } catch (err) {
            console.log(`err `, err)
        }
    }

    const verifyCodeInfoHandler = async (code = null, username = null) => {
        setIsLoading(true)
        setVerifyCodeInfo(state => ({
            ...state,
            noInfo: false
        }))
        try {
            const { data } = await axios.request({
                method: `POST`,
                url: BASE_URL + Url.FETCH_TG_VERIFY_CODES,
                headers: {
                    Authorization: Cookies.get(`moder_key`)
                },
                data: {
                    code: code ? code : null,
                    username: username ? username : null,
                }
            })

            setVerifyCodeInfo(state => ({
                ...state,
                codesList: data ? data : []
            }))

            if (!data) {
                setVerifyCodeInfo(state => ({
                    ...state,
                    noInfo: true
                }))
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }
        setIsLoading(false)
    }

    const deleteCodeHandler = async ({ target }) => {
        try {
            await axios.request({
                method: `DELETE`,
                url: BASE_URL + Url.DELETE_TG_VERIFY_CODE,
                data: {
                    ID: +target.id
                }
            })

            verifyCodeInfoHandler()
            setVerifyCodeInfo(state => ({
                ...state,
                code: ``,
                username: ``,
            }))

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {isLoading && <div className="spinner-absolute"><Spinner /></div>}
            <div className="moderator-tokens">
                <div className="moderator-label-main">
                    <label>
                        <span>аттестованные</span>
                        <input type="checkbox" checked={mentorTokens.certified} onChange={() => {
                            setMentorTokens(state => ({
                                ...state,
                                certified: !mentorTokens.certified
                            }))
                        }} />
                    </label>
                    <label>
                        <span>Количество:</span>
                        <input
                            type="number"
                            value={mentorTokens.count}
                            min="1"
                            onChange={(({ target }) => {
                                setMentorTokens(state => ({
                                    ...state,
                                    count: target.value ? +target.value : ``
                                }))
                            })} />
                    </label>
                    <button
                        className="button"
                        type="button"
                        onClick={createTokens}
                        id={Id.MENTOR}
                        disabled={!mentorTokens.count}>Создать токены для менторов</button>
                    {mentorTokensList &&
                        <ol className="tokens-list">
                            <span>Аттестованные: {mentorTokensList.certified ? "Да" : "Нет"}</span>
                            {mentorTokensList.tokens.map((token) => {
                                return (
                                    <li key={token}>{token}</li>
                                )
                            })}
                        </ol>
                    }
                </div>

                <div className="moderator-label-main">
                    <label>
                        <span>атестованные</span>
                        <input type="checkbox" checked={userTokens.certified} onChange={() => {
                            setUserTokens(state => ({
                                ...state,
                                certified: !userTokens.certified
                            }))
                        }} />
                    </label>
                    <label>
                        <span>Количество:</span>
                        <input
                            type="number"
                            value={userTokens.count}
                            min="1"
                            onChange={(({ target }) => {
                                setUserTokens(state => ({
                                    ...state,
                                    count: target.value ? +target.value : ``
                                }))
                            })} />
                    </label>
                    <button
                        className="button"
                        type="button"
                        onClick={createTokens}
                        id={Id.USER}
                        disabled={!userTokens.count}
                    >Создать токены для пользователей</button>
                    {userTokensList &&
                        <ol className="tokens-list">
                            <span>Атестованные: {userTokensList.certified ? "Да" : "Нет"}</span>
                            {userTokensList.tokens.map((token) => {
                                return (
                                    <li key={token}>{token}</li>
                                )
                            })}
                        </ol>
                    }
                </div>

            </div>

            <div className="export-block">
                <button
                    id={Id.MENTOR}
                    onClick={dataDownloadHandler}
                    className="button"
                >
                    скачать информацию о менторах
                </button>
                <button
                    id={Id.USER}
                    onClick={dataDownloadHandler}
                    // onClick={dataDownloadHandlerScam}
                    className="button"
                >
                    скачать информацию о пользователях
                </button>
            </div>

            <div className="export-block">
                <h3>Получить информацию о коде</h3>
                <label>
                    <span>Код</span>
                    <input
                        type="text"
                        value={verifyCodeInfo.code}
                        onChange={(({ target }) => {
                            setVerifyCodeInfo(state => ({
                                ...state,
                                code: target.value
                            }))
                        })}
                    />
                </label>
                <label>
                    <span>Имя пользователя</span>
                    <input
                        type="text"
                        value={verifyCodeInfo.username}
                        onChange={(({ target }) => {
                            setVerifyCodeInfo(state => ({
                                ...state,
                                username: target.value
                            }))
                        })}
                    />
                </label>
                <button
                    onClick={() => verifyCodeInfoHandler(verifyCodeInfo.code, verifyCodeInfo.username)}
                    className="button"
                >
                    Получить информацию
                </button>

                {
                    verifyCodeInfo.codesList.length ?
                        <ul className="verify-codes-list">
                            {verifyCodeInfo.codesList.map(({ ID, code, used }) => {
                                return (
                                    <li className="verify-codes-item">
                                        <span>код: <strong>{code}</strong></span>
                                        <span>использован: <strong>{used ? `да` : `нет`}</strong></span>
                                        <button
                                            type="button"
                                            className="button"
                                            id={ID}
                                            onClick={deleteCodeHandler}
                                        >
                                            Удалить код
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                        : null
                }
                {
                    verifyCodeInfo.noInfo
                        ? <div>нет информации</div>
                        : null
                }
            </div>
        </>
    )
}

export default ModeratorMain;