import {createReducer} from '@reduxjs/toolkit';
import { openRegistry, setInvitationCode, moderatorAuth } from './actions';

const initialState = {
  invitationCode: null,
  openDate: false,
  moderatorAuth: false,
};

const auth = createReducer(initialState, (builder) => {
  builder.addCase(setInvitationCode, (state, action) => {
    return {
      ...state,
      invitationCode: action.payload,
    };
  });

  builder.addCase(openRegistry, (state) => {
    return {
      ...state,
      openDate: true,
    };
  });
  builder.addCase(moderatorAuth, (state, action) => {
    return {
      ...state,
      moderatorAuth: action.payload,
    };
  });
});

export {auth};