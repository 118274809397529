import React from 'react';

const Final = () => {
  return (
    <div className="container__inner">
      <h3 className="container__inner__header">Ты сделал свой выбор!</h3>
      <span className="container__inner-text">
        Если у тебя нет сомнений - смело жми кнопку "Отправить". Напоминаем, что регистрация завершится через 24 часа после старта нового потока.
      </span>
    </div>
  )
}

export default Final;