import { useEffect, useState } from "react";

import axios from "axios";
import Cookies from 'js-cookie'
import browserHistory from "../browser-history";
import { Path } from "../const";

export const useFetchData = (url, method = `POST`, data = null, trigger = null) => {

  const [fetchedData, setFetchedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        
        const response = await axios.request({
          method,
          url,
          data,
          headers: {
            Authorization: Cookies.get(`moder_key`)
          }
        });
        setFetchedData(response.data);
      } catch (error) {
        console.log(`error `,error)
        Cookies.remove(`moder_key`)
        browserHistory.push(Path.MODERATOR_AUTH)
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, trigger]);

  return [fetchedData, isLoading, isError, setFetchedData];
};
