import React from 'react';

import './header.scss';

const Header = () => {
  return (
    <header className="header">
      <div className="header__container">
        <h2 className="header__header">Регистрация в группы к наставникам на новый поток</h2>
      </div>
    </header>
  )
}

export default Header;