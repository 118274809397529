import React from 'react';

import './background.scss';

const Background = () => {
  return (
    <div className="background"></div>
  );
};

export default Background;