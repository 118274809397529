import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { requireAuthorization, moderatorAuth } from '../../store/actions';
import { Path, AuthorizationStatus, BASE_URL, Url } from '../../const';

import ApiService from '../../store/api-actions';
import Spinner from '../spinner/spinner';
import Cookies from 'js-cookie'
import browserHistory from '../../browser-history';

const apiService = new ApiService();

const ModeratorAuth = () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('');
    const [errorBlock, setErrorBlock] = useState(false);
    const [spinner, setSpinner] = useState(true);

    const authorizationStatus = useSelector(({ LEADERS }) => LEADERS.authorizationStatus);


    
    const handleAuth = async (evt) => {
        evt.preventDefault();
        const moderatorData = {
            username: username,
            password: password
        }

        try {
            const {data} = await axios.request({
                method: `POST`,
                url: BASE_URL + Url.MODERATOR_AUTH,
                data: moderatorData
            })

            Cookies.set(`moder_key`, data.sessionKey)
            dispatch(moderatorAuth(true))
            browserHistory.push(Path.MODERATOR);
        } catch {
            setErrorBlock(true)
        }

    }

    return (
        <form
            onSubmit={handleAuth}
            className="auth">
            <h3 className="auth__header">Форма входа для модератора</h3>
            <label className="auth__label">
                <span className="auth__label-text">Введите логин</span>
                <input
                    autoComplete="username"
                    className="auth__input"
                    onInput={({ target }) => setUsername(target.value)}
                    type="login" />
            </label>
            <label className="auth__label">
                <span className="auth__label-text">Введите пароль</span>
                <input
                    autoComplete="password"
                    className="auth__input"
                    onInput={({ target }) => setPassword(target.value)}
                    type="password" />
            </label>
            {errorBlock
                ? <span className="auth__error">Неверный логин или пароль</span>
                : null}
            <button className="auth__submit button">Войти</button>
        </form>
    )
}

export default ModeratorAuth;