import React from 'react';

import './registration.scss';

const Registration = () => {
  return (
    <div className="container__inner">
      <h3 className="registration__header">Правила регистрации:</h3>
      <ol className="registration__rules">
        <li className="registration__rule">
          Выбрать нужно только одного наставника. ВНИМАНИЕ! Система регистрации не даст сделать повторный выбор наставника, поэтому подходите к выбору наставника с особой ответственностью.
        </li>
        <li className="registration__rule">
          После вступления в чат, наставник назначит первую встречу, на которой выберет тех, с кем продолжит работу.
        </li>
        <li className="registration__rule">
          ВНИМАНИЕ! Если после первой встречи наставник не взял тебя в свою группу из-за того, что не может тебе помочь в твоем запросе - тогда тебе будет доступна перерегистрация к другому наставнику, у которого на тот момент будут доступные места.
          Если ты не завершил регистрацию, или не вступил в чат по ссылке, полученной от бота, или не выполнил первое задание наставника, или не пришел на первую встречу - в этих случаях перерегистрация для тебя не будет доступна.
        </li>
        <li className="registration__rule">
          Завершение регистрации через 24 часа после старта нового Потока.
        </li>
      </ol>
    </div>
  )
};

export default Registration;