import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import LoadingScreen from '../loading-screen/loading-screen'

import './telegram-screen.scss';
import ErrorScreen from '../error-screen/error-screen';

const getTelegramBotLink = () => {
  const hostURI = window.location.host
  if (hostURI.includes("bishkek")) {
    return `terramentros_manager_bishkek_bot`
  }
  if (hostURI.includes(`samara`)) {
    return `terramentros_manager_samara_bot`
  }
  if (hostURI.includes(`kazan`)) {
    return `terramentros_manager_kazan_bot`
  }
  return `buffer_terra_mentors_bot`

}

const TelegramScreen = () => {

  const [copied, setCopied] = useState(false);

  console.log(`link`, getTelegramBotLink())
  const tgBotLink = `https://t.me/buffer_terra_mentors_bot`;
  // const tgBotLink = `https://t.me/${getTelegramBotLink()}`;

  const code = useSelector(({ LEADERS }) => LEADERS.code);
  const errorMessage = useSelector(({ LEADERS }) => LEADERS.errorMessage);

  if (errorMessage) {
    return <ErrorScreen />
  }

  if (!code) {
    return (
      <LoadingScreen />
    );
  }


  return (
    <div className="telegram">
      <span
        className="telegram__text">
        Спасибо за регистрацию! Сохраните этот код и отправьте его нашему боту в telegram
      </span>

      <div className="telegram__block">
        <span className="telegram__code">{code}</span>

        <CopyToClipboard text={code}
          onCopy={() => setCopied(true)}>
          <button
            className="telegram__action"
            type="button">
            <svg viewBox="0 0 16 16" width="16" height="16">
              <path fillRule="evenodd" d="M5.75 1a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-4.5zm.75 3V2.5h3V4h-3zm-2.874-.467a.75.75 0 00-.752-1.298A1.75 1.75 0 002 3.75v9.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-9.5a1.75 1.75 0 00-.874-1.515.75.75 0 10-.752 1.298.25.25 0 01.126.217v9.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.126-.217z">
              </path>
            </svg>
          </button>
        </CopyToClipboard>
      </div>

      {copied
        ? <span className="telegram__copied">код скопирован в буфер обмена!</span>
        : null}

      <a
        className="telegram__link"
        href={tgBotLink}
        target="_blank"
        rel="noreferrer" >
        Перейти в телеграм бот
      </a>
    </div>
  )
};

export default TelegramScreen;