import React from 'react';
import Spinner from '../spinner/spinner';

import './submit-button.scss';

const SubmitButton = ({ isSubmitDisabled, isFailedRegistration, isLoading }) => {
  return (
    <>
      {isLoading && <Spinner />}
      <button
        disabled={isSubmitDisabled}
        type="submit"
        className="submit button">
        {
          isFailedRegistration
            ? `произошла ошибка`
            : `отправить`
        }

      </button>
    </>
  )
};

export default SubmitButton;