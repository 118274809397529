import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import { fetchStudents } from '../../store/actions';
import { BASE_URL, Path, AuthorizationStatus, Url } from '../../const';

import Student from '../student/student';

import './mentor-page.scss';
import Spinner from '../spinner/spinner';
import browserHistory from '../../browser-history';

const MentorPage = () => {
  const dispatch = useDispatch();

  const [mentorEmail, setMentorEmail] = useState(``);
  const [requestID, setRequestID] = useState(``);
  const [isActive, setIsActive] = useState(null);
  const [activeTrigger, setActiveTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateStudentsList, setUpdateStudentsList] = useState(false);

  const students = useSelector(({ LEADERS }) => LEADERS.students);
  const authorizationStatus = useSelector(({ LEADERS }) => LEADERS.authorizationStatus);

  const token = localStorage.getItem(`token`);

  const activateMentorHandler = async () => {
    setIsLoading(true)

    try {
      const { data } = await axios
        .request({
          method: `PUT`,
          url: BASE_URL + Url.UPDATE_ACTIVE_STATUS,
          data: {
            active: !isActive
          },
          headers: {
            Authorization: localStorage.getItem(`token`)
          }
        })
      setActiveTrigger(state => !state)

    } catch (err) {
      console.log(err)
      localStorage.removeItem(`token`)
      browserHistory.push(Path.AUTHORIZATION)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const URL = BASE_URL + Url.FETCH_MENTORS_USERS;
    const token = localStorage.getItem(`token`);

    // if (!students) {
    axios
      .get(URL, { 'headers': { 'Authorization': token } })
      .then(({ data }) => dispatch(fetchStudents(data)))
      .catch(() => {
        localStorage.removeItem(`token`)
        browserHistory.push(Path.AUTHORIZATION)
        window.location.reload()
      })
    // }
  }, [updateStudentsList]);

  useEffect(() => {
    const token = localStorage.getItem(`token`);

    const fetchMentorStatus = async () => {
      try {
        const { data } = await axios.request({
          method: `GET`,
          url: BASE_URL + Url.GET_ACTIVE_STATE,
          headers: { Authorization: token }
        })
        setIsActive(data.active)
      } catch {
        browserHistory.push(Path.AUTHORIZATION)
      }
    }

    fetchMentorStatus()
  }, [activeTrigger])

  if (authorizationStatus === AuthorizationStatus.NO_AUTH) {
    return <Redirect to={Path.AUTHORIZATION} />;
  }


  return (
    <div className="mentor">
      {isLoading && <Spinner />}
      <div className="mentor__container">
        <button
          className='button'
          style={{ marginBottom: `16px` }}
          onClick={() => {
            localStorage.removeItem(`token`)
            browserHistory.push(Path.AUTHORIZATION)
            window.location.reload()
          }}
        >выйти</button>

        <div className='active__mentor'>
          {/* <label>
            <span>Активировать себя</span>
            <input
              type="checkbox"
              value={isActive}
              onChange={(({ target }) => setIsActive(target.checked))}
            />
          </label> */}
          <button
            className='button'
            onClick={activateMentorHandler}
          >
            {isActive !== null ?
              isActive
                ? `Деактивировать себя`
                : `Активировать себя`
              : `loading...`}
            {/* Подтвердить */}
          </button>
        </div>

        <ul className="students-list">
          <h2>Список участников</h2>
          {!students && <h1 className="mentor__empty">К вам никто не записался</h1>}
          {
            students && students.map((student, idx) => {
              return <Student
                token={token}
                key={idx}
                student={student}
                setUpdateStudentsList={setUpdateStudentsList} />
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default MentorPage;